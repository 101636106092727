import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { logoutAction } from "../slices/authSlice";
import { RootStateType } from "../store";

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
    : process.env.REACT_APP_PRODUCTION_BASE_URL;

export const baseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig["method"];
    body?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosRequestConfig["headers"];
    abortController?: AbortController;
    onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
  },
  unknown,
  unknown
> = async (
  {
    url,
    method = "GET",
    body,
    params,
    headers,
    abortController,
    onUploadProgress,
  },
  { getState, dispatch }
) => {
  try {
    const { auth } = getState() as RootStateType;

    const axiosHeader = () => {
      let result = { ...headers } as any;
      if (auth?.token) {
        result.authorization = `Bearer ${auth.token}`;
      }
      return result;
    };

    const result = await axios({
      url: baseUrl + url,
      method,
      data: body,
      params,
      headers: axiosHeader(),
      ...(abortController && { signal: abortController.signal }),
      onUploadProgress,
    });
    return { data: result.data };
  } catch (axiosError) {
    const e = axiosError as AxiosError;
    const error = {
      status: e.response?.status,
      axiosMessage: e.message,
      data: (e?.response?.data as any)?.message || e.message,
    };
    console.log("rtk query error handler => ", error);
    if (error?.data === "canceled") {
      return { error };
    }
    if (error.status === 401) {
      dispatch(logoutAction());
    }

    return { error };
  }
};

import { Box, Container, Stack, Toolbar } from "@mui/material";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "../molecules/dashboardTemplate/Footer";
import { Header } from "../molecules/dashboardTemplate/Header";

export const DashboardTemplate: FC<{ children: any }> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      const id = window.location.hash.replace("#", "");

      const element = document.getElementById(id);
      if (element) {
        const yOffset = -100;
        const y = Math.max(
          0,
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        );

        window.scrollTo({ top: y, behavior: "smooth" });
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      }
    }, 100);
  }, [location]);

  return (
    <Stack sx={{ minHeight: "100vh" }} justifyContent="space-between">
      <Stack sx={{ flexGrow: 1 }}>
        <Header />
        <Toolbar sx={{ my: { xs: "30px", md: "5px" } }} />
        <Container
          maxWidth="xl"
          sx={{
            my: { sm: 0, md: 4 },
          }}
          id="home"
        >
          {children}
        </Container>
      </Stack>
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          height: "100%",
          position: "relative",
        }}
      >
        <img
          style={{ width: "100%" }}
          alt="timeLine"
          src="/images/time line desktop.jpg"
        />
        <Box
          sx={{
            position: { md: "absolute" },
            bottom: { md: 10, xs: 20 },
            backgroundColor: "#4B9461",
            width: "100%",
            mt: { xs: "-100px" },
          }}
        >
          <Footer />
        </Box>
      </Box>
    </Stack>
  );
};

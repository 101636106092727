import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const LeftArrow: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 21C15.744 21 15.488 20.902 15.293 20.707L7.293 12.707C6.902 12.316 6.902 11.684 7.293 11.293L15.293 3.293C15.684 2.902 16.316 2.902 16.707 3.293C17.098 3.684 17.098 4.316 16.707 4.707L9.414 12L16.707 19.293C17.098 19.684 17.098 20.316 16.707 20.707C16.512 20.902 16.256 21 16 21"
        fill="black"
      />
    </SvgIcon>
  );
};

import { Grid, Stack, Typography } from "@mui/material";
import { AssetCard } from "./AssetCard";
import { ContractIcon } from "../atoms/icons/ContractIcon";
import { useAssetControllerGetMyAssetsQuery } from "src/app/services/generated-api";

export const MyAssets = () => {
  const { data: assets = [] } = useAssetControllerGetMyAssetsQuery();

  return (
    <>
      <Stack direction="row" spacing={1}>
        <ContractIcon />
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          قراردادهای من
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {[...assets].reverse().map((asset) => (
          <Grid item xs={12} sm={12} md={4} key={asset.id}>
            <AssetCard asset={asset} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { LeftArrow2 } from "../atoms/icons/LeftArrow2";
import { AuthDialog } from "../organisms/authentication/AuthDialog";
import { ChargeWallet } from "./ChargeWallet";

export const PaymentDialog: FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  const [openAuthDialog, setOpenAuthDialog] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontSize: "30px", fontWeight: "600" }}>
              شارژ کیف پول
            </Typography>
            <LeftArrow2 sx={{ fontSize: "30px" }} onClick={handleClose} />
          </Stack>
        </DialogTitle>

        <Box sx={{ p: "60px 50px 90px 50px" }}>
          <ChargeWallet handleClose={handleClose} />
        </Box>
      </Dialog>
      {openAuthDialog && (
        <AuthDialog
          open={openAuthDialog}
          handleClose={() => {
            setOpenAuthDialog(false);
          }}
        />
      )}
    </>
  );
};

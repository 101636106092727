import { Box, Button, Theme, useMediaQuery } from "@mui/material";
import { FC, useState } from "react";
import { BuyDialog } from "../organisms/buy/BuyDialog";
import { PaymentDialog } from "../molecules/PaymentDialog";
import { AuthDialog } from "../organisms/authentication/AuthDialog";

export const PurchaseButton: FC<{ stickyMode?: boolean }> = ({
  stickyMode = false,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [openBuyDialog, setOpenBuyDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openAuthDialog, setOpenAuthDialog] = useState(false);

  return (
    <>
      {stickyMode ? (
        <Box
          sx={{
            position: {
              xs: "sticky",
              sm: "unset",
            },
            left: 0,
            bottom: 10,
            width: "100%",
            py: 1,
            zIndex: 5,
            textAlign: "center",
            display: { sm: "none", md: "none" },
          }}
        >
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="primary"
            onClick={() => setOpenBuyDialog(true)}
            sx={{
              fontSize: "28px",
              borderRadius: "10px",
              boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)",
            }}
          >
            خرید
          </Button>
        </Box>
      ) : (
        <Button
          variant="contained"
          sx={{
            fontSize: "30px",
            px: "60px",
            borderRadius: "15px",
            fontWeight: "600",
          }}
          onClick={() => setOpenBuyDialog(true)}
        >
          خرید
        </Button>
      )}

      <BuyDialog
        open={openBuyDialog}
        handleClose={() => setOpenBuyDialog(false)}
        openPaymentDialog={() => {
          setOpenPaymentDialog(true);
          if (!isMobile) {
            setOpenBuyDialog(false);
          }
        }}
        openAuthDialog={() => {
          setOpenAuthDialog(true);
          setOpenBuyDialog(false);
        }}
      />

      <PaymentDialog
        open={openPaymentDialog}
        handleClose={() => {
          setOpenPaymentDialog(false);
          setOpenBuyDialog(true);
        }}
      />

      <AuthDialog
        open={openAuthDialog}
        handleClose={() => {
          setOpenAuthDialog(false);
          setOpenBuyDialog(true);
        }}
      />
    </>
  );
};

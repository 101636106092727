import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import home from "src/assets/images/header.jpg";
import { ReadMore } from "src/components/atoms/ReadMore";

export const IntroSection = () => {
  const IntroText =
    "اگر به دنبال راهی برای حفظ ارزش پول و کسب سود هستید, پیشنهادی برای شما داریم. خرید املاک و مستغلات به عنوان یکی از بهترین روش‌های حفظ ارزش پول و کسب سود شناخته‌شده‌ است. ما به عنوان یکی از متخصصان در حوزه سرمایه‌گذاری در بازار املاک و مستغلات, اعتقاد داریم که برای ورود به این بازار احتیاجی به سرمایه‌های کلان نیست. به همین منظور این امکان را فراهم کرده‌ایم تا با حداقل سرمایه, به صورت آنلاین, زمین خریداری کنید.";

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded
    ? IntroText
    : IntroText.slice(0, 280) + "...";

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      textAlign="center"
      spacing={{ xs: 2, sm: 10 }}
    >
      <Grid item xs={12} md={12} order={{ xs: 2, sm: 2, md: 2 }}>
        <Typography
          sx={{
            fontSize: { xs: "22px" },
            fontWeight: "bold",
            textAlign: "left",
            display: { md: "none" },
            mt: "10px",
            mb: "20px",
          }}
        >
          سرمایه گذاری ولی مطمئن
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "25px" },
            fontWeight: { xs: "400", md: "400" },
            textAlign: "justify",
            mb: "10px",
          }}
        >
          {displayedText}
        </Typography>
        {!isExpanded && (
          <Box
            textAlign="right"
            mt="10px"
            mb="20px"
            onClick={handleExpandClick}
          >
            <ReadMore />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={12} order={{ xs: 1, sm: 1, md: 1 }}>
        <Box
          sx={{
            borderRadius: "10px",
            minHeight: { sm: 300, md: 500 },
            position: "relative",
            "&:before": {
              content: '""',
              backgroundColor: "green",
              transform: "rotate(-10deg)",
              borderRadius: "10px",
              width: "96%",
              position: "absolute",
              height: "90%",
              top: "10%",
              left: "2%",
              zIndex: "-1",
            },
          }}
        >
          <img
            src={home}
            alt="home"
            style={{
              maxWidth: "100%",
              borderRadius: "10px",
            }}
          />
          <Typography
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              fontSize: "50px",
              fontWeight: "bold",
              position: "absolute",
              bottom: "70px",
              left: "70px",
              color: "white",
              zIndex: "5",
            }}
          >
            سرمایه گذاری ولی مطمئن
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

import { Box, Stack, Typography } from "@mui/material";

export const ChartLegend = () => {
  return (
    <Box
      sx={{ display: "flex", width: "fit-content", px: "20px", alignItems: "center" }}
    >
      <Stack spacing={2}>
        <Stack direction="row">
          <Box
            style={{
              backgroundColor: "#FBBC04",
              width: 25,
              height: 25,
              marginLeft: 10,
              borderRadius: "50%",
            }}
          />
          <Typography style={{ color: "black" }}>حجم باقی مانده</Typography>
        </Stack>
        <Stack direction="row">
          <Box
            style={{
              backgroundColor: "#4B9461",
              width: 25,
              height: 25,
              marginLeft: 10,
              borderRadius: "50%",
            }}
          />
          <Typography style={{ color: "black" }}>حجم خریداری شده</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const ProfileEmail: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M22 10.5V15.5C22 19 20 20.5 17 20.5H6.99997C3.99997 20.5 1.99997 19 1.99997 15.5V8.50002C1.99997 5.00002 3.99997 3.50002 6.99997 3.50002H14"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M7.00003 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5L15.06 10.56"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </SvgIcon>
  );
};

import { Box, Divider, Grid, Typography } from "@mui/material";
import { PurchaseButton } from "src/components/atoms/PurchaseButton";
import { DashboardTemplate } from "src/components/templates/Dashboard";
import { IntroSection } from "src/components/organisms/landing/IntroSection";
import { Contracts } from "src/components/organisms/landing/Contracts";
import { VideoSection } from "src/components/organisms/landing/VideoSection";
import { BlogSection } from "src/components/organisms/landing/BlogSection";
import { Counsel } from "src/components/molecules/Counsel";
import QuestionAnswer from "src/components/organisms/landing/QuestionAnswer";
import { ChartSection } from "src/components/organisms/landing/ChartSection";

export const OldLanding = () => {
  return (
    <DashboardTemplate>
      <IntroSection />
      <VideoSection />
      <BlogSection />
      <Box sx={{ my: 5 }}>
        <ChartSection />
      </Box>
      <Contracts />
      <Box sx={{ my: 5 }}>
        <Counsel />
      </Box>
      <QuestionAnswer />
      <PurchaseButton stickyMode />
    </DashboardTemplate>
  );
};

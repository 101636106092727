import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Theme,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { cloneElement, useState } from "react";
import { Call } from "../../atoms/icons/Call";
import { AuthDialog } from "../../organisms/authentication/AuthDialog";
import { SidebarMenu } from "../SidebarMenu";
import { useAppDispatch } from "src/app/hooks";
import { CallWhite } from "src/components/atoms/icons/CallWhite";
import { logoutAction } from "src/app/slices/authSlice";
import { PurchaseButton } from "src/components/atoms/PurchaseButton";
import { useUserControllerGetProfileQuery } from "src/app/services/generated-api";

interface Props {
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: {
      backgroundColor: trigger ? "white" : "#ffffffee",
      transition: "200ms",
    },
  });
}

export const Header = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { data: user } = useUserControllerGetProfileQuery();

  const [openAuthDialog, setOpenAuthDialog] = useState(false);

  const [initAuthPage, setInitAuthPage] = useState<number>();

  const dispatch = useAppDispatch();

  return (
    <>
      <ElevationScroll>
        <AppBar>
          <Toolbar sx={{ my: { xs: "20px", md: "20px" } }}>
            <Container maxWidth="xl">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {isMobile ? (
                  <>
                    <SidebarMenu
                      setOpenAuthDialog={setOpenAuthDialog}
                      setInitAuthPage={setInitAuthPage}
                    />
                    <Box sx={{ height: { xs: 40, sm: 45 } }}>
                      <Link to="/#home">
                        <img
                          src="/images/logo.svg"
                          alt="logo"
                          style={{ height: "100%" }}
                        />
                      </Link>
                    </Box>
                    <IconButton component={Link} to="#Counsel">
                      <Call />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ lg: 6, md: 1 }}
                    >
                      <Box sx={{ height: { xs: 30, sm: 60 } }}>
                        <Link to="/#home">
                          <img
                            src="/images/logo.svg"
                            alt="logo"
                            style={{ height: "100%" }}
                          />
                        </Link>
                      </Box>
                      <Button
                        sx={{
                          color: "black",
                          fontSize: { sm: "16px", md: "18px", lg: "20px" },
                          fontWeight: "400",
                          width: "fit-content",
                        }}
                        component={Link}
                        to="/#home"
                      >
                        صفحه اصلی
                      </Button>
                      {!user && (
                        <Button
                          sx={{
                            color: "black",
                            fontSize: { sm: "16px", md: "18px", lg: "20px" },
                            fontWeight: "400",
                            width: "fit-content",
                          }}
                          component={Link}
                          to="/#Counsel"
                        >
                          مشاوره
                        </Button>
                      )}
                      {user && (
                        <Button
                          sx={{
                            color: "black",
                            fontSize: { sm: "16px", md: "18px", lg: "20px" },
                            fontWeight: "400",
                            width: "fit-content",
                          }}
                          component={Link}
                          to="/userPanel"
                        >
                          پنل کاربری
                        </Button>
                      )}
                      {user && (
                        <Button
                          sx={{
                            color: "black",
                            fontSize: { sm: "16px", md: "18px", lg: "20px" },
                            fontWeight: "400",
                            width: "fit-content",
                          }}
                          onClick={() => {
                            setOpenAuthDialog(true);
                            setInitAuthPage(2);
                          }}
                        >
                          اطلاعات حساب کاربری
                        </Button>
                      )}
                      <Button
                        sx={{
                          color: "black",
                          fontSize: { md: "18px", lg: "20px" },
                          fontWeight: "400",
                          width: "fit-content",
                        }}
                      >
                        درباره ما
                      </Button>
                    </Stack>

                    <Stack direction="row" spacing={{ sm: 1, md: 2, lg: 4 }}>
                      {user && (
                        <>
                          <Button
                            variant="contained"
                            sx={{ borderRadius: "10px" }}
                            component={Link}
                            to="#Counsel"
                          >
                            <CallWhite sx={{ fontSize: "40px" }} />
                          </Button>
                        </>
                      )}
                      <PurchaseButton />
                      {!user && (
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: "30px",
                            px: "60px",
                            borderRadius: "15px",
                            fontWeight: "600",
                          }}
                          onClick={() => setOpenAuthDialog(true)}
                        >
                          ورود
                        </Button>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {openAuthDialog && (
        <AuthDialog
          open={openAuthDialog}
          handleClose={() => {
            setOpenAuthDialog(false);
            setInitAuthPage(undefined);
          }}
          initPageNumber={initAuthPage}
        />
      )}
    </>
  );
};

import { Divider, Stack, Typography } from "@mui/material";
import { Wallet } from "../atoms/icons/Wallet";
import { Warning2 } from "../atoms/icons/Warning2";
import {
  useAssetControllerMyAssetsValuationQuery,
  useInstallmentControllerGetNearestInstallmentQuery,
  useUserControllerGetProfileQuery,
} from "src/app/services/generated-api";
import { formatPrice } from "src/utils/price";
import { useMemo } from "react";
import { e2p } from "src/utils/convertNumber";

export const WalletInfo = () => {
  const { data: nearestInstallment } =
    useInstallmentControllerGetNearestInstallmentQuery();

  const { data: myAssetsValuation = [] } =
    useAssetControllerMyAssetsValuationQuery();

  const totalAssetsValuation = useMemo(
    () =>
      myAssetsValuation.reduce(
        (prev, current) => current.totalAmount * current.totalSize + prev,
        0
      ),
    [myAssetsValuation]
  );

  const { data: user } = useUserControllerGetProfileQuery();
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Wallet sx={{ fontSize: { md: "30px " } }} />
        <Typography
          sx={{ fontSize: { xs: "20px", md: "25px" }, fontWeight: "500" }}
        >
          کیف پول
        </Typography>
      </Stack>
      <Stack spacing={3}>
        <Stack
          direction="row"
          sx={{
            border: { md: "1px solid #41683B", xs: "1px solid #C8CACE" },
            p: "20px",
            width: "100%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ width: "60%", fontSize: "18px" }}>
            موجودی فعلی
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            color="#E1E2E4"
            sx={{ mx: "10px" }}
          />
          <Typography
            sx={{ width: "100%", textAlign: "right", fontSize: "18px" }}
          >
            {e2p(formatPrice(user?.walletBalance || 0, true))} {" تومان"}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            border: { md: "1px solid #41683B", xs: "1px solid #C8CACE" },
            p: "20px",
            width: "100%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ width: "60%", fontSize: "18px" }}>
            قسط آتی
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            color="#E1E2E4"
            sx={{ mx: "10px" }}
          />
          <Typography
            sx={{ width: "100%", textAlign: "right", fontSize: "18px" }}
          >
            {e2p(
              nearestInstallment?.amount
                ? nearestInstallment?.amount + "تومان"
                : "موجود نیست"
            )}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            border: { md: "1px solid #41683B", xs: "1px solid #C8CACE" },
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            p: "20px",
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ width: "60%", fontSize: "18px" }}>
            دارایی های فعلی
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            color="#E1E2E4"
            sx={{ mx: "10px" }}
          />
          <Typography
            sx={{ width: "100%", textAlign: "right", fontSize: "18px" }}
          >
            {`${e2p(totalAssetsValuation)} تومان`}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Warning2 />
        <Typography> اقساط سر موعد مقرر از کیف پول کسر می شود.</Typography>
      </Stack>
    </Stack>
  );
};

import { Box, Grid, Typography } from "@mui/material";
import { TermsText } from "./TermsText";

export const Contracts = () => {
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item order={{ xs: 1, md: 2 }}>
        <Box
          sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            borderRadius: "12px",
            p: "20px 30px",
            m: "30px 10px",
          }}
        >
          <Typography sx={{ fontSize: "1.5em", py: "10px", fontWeight: "500" }}>
            شرایط و قوانین:
          </Typography>
          <Box>
            <TermsText />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

import { Typography } from "@mui/material";

export const TermsText = () => {
  return (
    <>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        1. در خرید <b>نقدی</b>، 45% مبلغ به صورت <b>پیش پرداخت</b> دریافت میشود،
        50% دیگر تا موعد 45 روز بعد و 5% دیگر هنگام تحویل زمین دریافت می‌گردد.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        2. در خرید <b>قسطی</b>، 35% مبلغ به صورت <b>پیش پرداخت</b> دریافت میشود
        ، 60% مبلغ به صورت 6 قسط طی 6 ماه (فاصله یک ماه) و 5% دیگر هنگام تحویل
        زمین دریافت می‌گردد.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        3. در صورتی که مبلغ سرمایه گذاری شما <b>کمتر از 1 میلیارد تومان</b> باشد
        تنها امکان خرید نقدی برای شما فعال خواهد شد.{" "}
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        4. درصورتی که به هر دلیل قرار داد خود را <b>فسخ</b> کنید، ضمن کسر جریمه
        به میزان .......... ، مبلغ بعد از گذشت 3 ماه به حساب شما عودت داده خواهد
        شد.{" "}
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        5. درصورتی که در زمان تعیین شده بر روی سایت، پیش پرداخت خود را تسویه
        نکنید، قرارداد شما لغو و مبلغ پرداخت شده پس از گذشت 1 ماه به حساب شما
        عودت داده میشود.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        6. در صورت عدم تسویه‌ی پرداخت‌ها در زمان تعیین شده، برای شما جریمه
        دیرکرد اعمال خواهد شد و در صورت عدم پرداخت بیش از یک ماه، قرارداد شما
        فسخ خواهد شد.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        7. باتوجه به تعداد قطعاتی که خریداری کرده‌اید، به ازای هر قطعه‌ی کامل،
        <b>سند شخصی</b> دریافت خواهید کرد و به ازای قطعات اعشاری،{" "}
        <b>گواهینامه مالکیت</b> دریافت می‌کنید.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        8. شرکت مهر رضوی به عنوان مجری پروژه، در ازای عدم انجام تعهدات خود
        جریمه‌هایی را به منظور <b>جبران خسارت</b> سرمایه گذاران در نظر گرفته
        است.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        9. <b>جانمایی زمین‌ها</b> به شیوه قرعه‌کشی معین خواهد شد و همه‌ی سرمایه
        گذاران شرایط یکسانی در دریافت زمین‌ها دارند. زمان قرعه کشی متعاقبا اعلام
        خواهد شد.
      </Typography>
      <Typography
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight="40px"
        textAlign="justify"
      >
        10. امکان فروش زمین‌ها برای تمام خریداران از روز انعقاد قرارداد فراهم
        می‌باشد.
      </Typography>
    </>
  );
};

import { Grid } from "@mui/material";
import { LandingCard } from "src/components/molecules/LandingCard";

export const LandingCards = () => {
  return (
    <Grid
      container
      sx={{
        display: "grid",
        width: "100%",
        gridTemplateColumns: "35% 30% 35%",
        p: "10px",
        my: "50px",
      }}
      spacing={6}
    >
      <Grid
        item
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: 3,
        }}
      >
        <Grid
          container
          sx={{ display: "grid", gridTemplateRows: "50% 50%" }}
          spacing={8}
        >
          <Grid item sx={{ gridRowStart: 1, gridRowEnd: 2 }}>
            <LandingCard
              direction="row"
              src="/images/2.png"
              title="شفافیت و اصالت"
              content="تمام اسناد و مدارک پروژه در دفاتر مرکزی قابل رویت و استعلام می‌باشد. همچنین تمام طرح ها و برنامه‌های اجرایی به اطلاع سرمایه گذاران خواهد رسید."
            />
          </Grid>
          <Grid item sx={{ gridRowStart: 2, gridRowEnd: 3 }}>
            <LandingCard
              direction={"row-reverse"}
              src="/images/3.png"
              title="بر مدار کلان شهر ها"
              content="قرار گرفتن در حومه‌ی شهرهای اصلی تهران، کرج و قزوین و همچنین علاقه‌ی روزافزون مردم به سکونت در حومه‌ی شهرهای بزرگ، از امتیازات ویژه‌ی این اراضی است."
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          gridColumnStart: 3,
          gridColumnEnd: 4,
        }}
      >
        <LandingCard
          direction="column"
          src="/images/1.png"
          title="بافت ویلایی و خوش نشین"
          content="بر اساس شرایط آب و هوایی منطقه و امکانات در نظر گرفته شده در طرح تفصیلی و همچنین تاثیر پذیری از مناطق همجوار نظیر کوهسار، هلدینگ مهر رضوی برنامه ریزی جامعی جهت طراحی شهری برتر و بهره برداری حداکثری از این ظرفیت ها انجام داده است."
        />
      </Grid>
      <Grid item sx={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
        <LandingCard
          direction="column"
          src="/images/5.png"
          title="ظرفیت رشد قیمت بالا "
          content="با توجه به اینکه در این مرحله، سرمایه گذاران پیش از جانمایی و تفکیک اراضی و ارائه سند تک برگ اقدام به سرمایه گذاری می‌کنند و همچنین با در نظر گرفتن سرعت رشد منطقه و تورم سالانه، پیش بینی می‌شود تا پایان سال جاری شاهد افزایش چند برابری قیمت زمین‌های منطقه باشیم."
        />
      </Grid>
      <Grid
        item
        sx={{
          gridColumnStart: 2,
          gridColumnEnd: 4,
        }}
      >
        <Grid
          container
          sx={{
            display: "grid",
            gridTemplateRows: "50% 50%",
          }}
          spacing={8}
        >
          <Grid item sx={{ gridRowStart: 1, gridRowEnd: 2 }}>
            <LandingCard
              direction={"row-reverse"}
              src="/images/4.png"
              title="سرعت اجرای پروژه"
              content="هلدینگ مهر رضوی به عنوان یک مجموعه با تجربه در حوزه عمرانی، در نظر دارد به منظور کاهش هزینه‌ها و افزایش بهره‌وری مالی، با حداکثر سرعت، عملیات زیرسازی، تفکیک، ارائه سند و فازهای عمرانی را به پایان برساند."
            />
          </Grid>
          <Grid item sx={{ gridRowStart: 2, gridRowEnd: 3 }}>
            <LandingCard
              direction="row"
              src="/images/6.png"
              title="تجمیع عملیات عمرانی"
              content="با سپردن عملیات عمرانی به تیم مجرب هلدینگ مهر رضوی، سرمایه گذاران این امتیاز را خواهند داشت تا علاوه بر صرفه جویی قابل توجه در هزینه‌ها، خانه‌هایی هماهنگ با روح شهر و با کیفیت  بالا داشته باشند."
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
        <Grid
          container
          sx={{ display: "grid", gridTemplateColumns: "15% 35% 35% 15%" }}
          spacing={6}
        >
          <Grid
            item
            sx={{
              gridColumnStart: 2,
              gridColumnEnd: 3,
            }}
          >
            <LandingCard
              direction="column"
              src="/images/8.png"
              title="خدمات حقوقی و اداری"
              content="هلدینگ مهر رضوی در تمام مراحل پروژه به عنوان مشاور و کارشناس در کنار سرمایه‌گذاران خواهد بود. همچنین ضمن ارائه گزارش‌های منظم، امکانات مختلف حقوقی و معاملاتی را به منظور بهره‌مندی هر چه بیشتر عزیزان، در اختیار سرمایه‌گذاران خواهد گذاشت."
            />
          </Grid>
          <Grid
            item
            sx={{
              gridColumnStart: 3,
              gridColumnEnd: 4,
            }}
          >
            <LandingCard
              direction="column"
              src="/images/7.png"
              title="شهرسازی و معماری متمایز"
              content="هلدینگ مهر رضوی در نظر دارد تا با طراحی جامع و مبتکرانه شهری و توسعه‌ی سریع زیر ساخت‌ها و اجرای عملیات عمرانی در کوتاه ترین زمان ممکن، شهری کارآمد و متوازن و مبتنی بر فناوری‌های روز معماری و شهر سازی ایجاد کند."
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { useProjectControllerGetProjectQuery } from "src/app/services/generated-api";

const defaultProjectId = process.env.REACT_APP_DEFAULT_PROJECT;

export const useProject = () => {
  const { data: project } = useProjectControllerGetProjectQuery(
    { id: defaultProjectId! },
    { skip: !defaultProjectId }
  );

  return project;
};

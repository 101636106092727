import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ReadMore } from "src/components/atoms/ReadMore";

export const BlogSection = () => {
  const blogText =
    "لدینگ مهر رضوی با پشتوانه سال ها تجربه در صنعت ساخت و ساز، اقدام به طراحی پروژه عمرانی حکمت رضوی کرده است. این پروژه طرح تفصیلی شهر جدید هشتگرد واقع در استان البرز است که در بهمن ماه سال ۱۴۰۱ توسط استانداری ابلاغ گردیده است.فاز اول این پروژه شامل ۲۰۰ قطعه زمین ۲۰۰ متری میباشد که بر بستر این سایت به فروش میرسد. این امکان فراهم شده تا شما بتوانید هر سهمی از زمین را که مایل هستید خریداری کنید و با مبلغ دلخواه خود در این پروژه سرمایه گذاری کنید.محل تقریبی اراضی حد فاصل بین کردان، شهر جدید هشتگرد(بیست کیلومتری شهر کرج) میباشد. شمال اراضی روستای کوشک زر قرار داشته و جنوب آن اتوبان کرج قزوین میباشد.";

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded ? blogText : blogText.slice(0, 280) + "...";

  return (
    <Box sx={{ padding: { md: "100px 50px" } }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        textAlign="center"
        spacing={{ xs: 2, sm: 10 }}
      >
        <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
          <Box
            sx={{
              backgroundColor: "green",
              transform: {
                xs: "translate(-20px, -20px)",
                sm: "translate(-25px, -25px)",
              },
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                transform: {
                  xs: "translate(20px, 20px)",
                  sm: "translate(25px, 25px)",
                },
                borderRadius: "10px",
              }}
            >
              <img
                src="/images/blog.jpg"
                alt="homeBanner"
                style={{ borderRadius: "10px", maxWidth: "100%" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
          <Typography
            sx={{
              display: { xs: "none", md: "block" },
              fontSize: "35px",
              fontWeight: "bold",
              textAlign: "left",
              mb: "40px",
            }}
          >
            هلدینگ رضوی
          </Typography>
          <Typography
            textAlign="justify"
            fontSize={{ xs: "18px", md: "25px" }}
            fontWeight={{ md: "500" }}
          >
            {displayedText}
          </Typography>
          {!isExpanded && (
            <Box textAlign="right" my="20px" onClick={handleExpandClick}>
              <ReadMore />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

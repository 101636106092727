import { Box, Slider, Theme, useMediaQuery } from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useRef, useState } from "react";

export const LandingMap = () => {
  const containerRef = useRef<HTMLDivElement>();
  const imageContainerRef = useRef<HTMLDivElement>();
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const [sliderValue, setSliderValue] = useState(75);

  const mapRef = useRef<google.maps.Map>();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCqZ4Pf2L6QurNHHPmJAcwRkjblmXQZGL4",
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const moveDivisor = (value: number) => {
    if (!imageContainerRef.current || !sliderRef.current) {
      return;
    }
    imageContainerRef.current.style.width = value + "%";
    sliderRef.current.style.right = value + "%";

    setSliderValue(value);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        height: "600px",
        width: "100%",
        maxWidth: 1000,
        m: "auto",
      }}
    >
      <Box
        ref={imageContainerRef}
        sx={{
          position: "absolute",
          height: "600px",
          width: `${sliderValue}%`,
          top: 0,
          left: 0,
          zIndex: 2,
          overflow: "hidden",
        }}
      >
        <img
          src="/images/tafsili.jpg"
          alt="tasili"
          style={{
            width: "1050px",
            height: "750px",
            transform: isMobile ? "translateX(150px)" : "",
          }}
        />
      </Box>
      <Box
        ref={sliderRef}
        sx={{
          position: "absolute",
          top: 0,
          left: `${sliderValue}%`,
          height: "100%",
          width: 10,
          background: "#ccc",
          zIndex: 4,
          transform: "translateX(-50%)",
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: "30%",
          right: 0,
          zIndex: 4,
        }}
      >
        <Slider
          orientation="horizontal"
          valueLabelDisplay="off"
          value={sliderValue}
          onChange={(e, value) => moveDivisor(value as number)}
          sx={{
            "& .MuiSlider-rail": { opacity: 0 },
            "& .MuiSlider-track": { opacity: 0 },
            "& .MuiSlider-thumb": {
              backgroundImage: "url(/images/map-slider.png)",
              backgroundSize: "45px 200px",
              backgroundColor: "unset",
              borderRadius: 0,
              height: 200,
              width: 45,
              "&:before": {
                boxShadow: "none",
              },
              "&:hover": {
                boxShadow: "none",
              },
            },
          }}
        />
      </Box>
      <Box
        style={{
          position: "absolute",
          height: "600px",
          width: "100%",
          zIndex: -2,
          top: 0,
          left: 0,
          overflow: "hidden",
        }}
      >
        {isLoaded && (
          <GoogleMap
            clickableIcons={false}
            mapContainerStyle={{
              height: "600px",
              width: "300%",
              transform: isMobile
                ? "translateX(calc(50% + 150px))"
                : "translateX(50%)",
            }}
            center={{
              lat: 35.9578278,
              lng: 50.7763381,
            }}
            onLoad={(map: google.maps.Map) => {
              mapRef.current = map;
            }}
            zoom={15}
            mapTypeId={google.maps.MapTypeId.HYBRID}
          ></GoogleMap>
        )}
      </Box>
    </Box>
  );
};

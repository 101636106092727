export const p2e = (s: string | number, onlyNumber?: boolean) => {
  const converted = ("" + s).replace(/[۰-۹]/g, (d) =>
    "۰۱۲۳۴۵۶۷۸۹".indexOf(d).toString()
  );
  if (onlyNumber) {
    return converted.replace(/\D/g, "");
  }
  return converted;
};

export const e2p = (s: string | number, onlyNumber?: boolean) => {
  const convertedNumber = ("" + s).replace(
    /[0-9]/g,
    (d) => "۰۱۲۳۴۵۶۷۸۹"[parseInt(d)]
  );
  if (onlyNumber) {
    return convertedNumber.replace(/\D/g, "");
  }
  return convertedNumber;
};

// .indexOf(d).toString()

import { Button, Container, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useInstallmentControllerPayInstallmentMutation } from "src/app/services/generated-api";
import {
  useAssetControllerGetAssetQuery,
  useInstallmentControllerGetInstallmentsQuery,
} from "src/app/services/generated-api";
import { InstallmentItem } from "src/components/molecules/InstallmentItem";
import { DashboardTemplate } from "src/components/templates/Dashboard";
import { formatDate } from "src/utils/dateFormatter";

export const Asset = () => {
  const { assetId } = useParams() as { assetId: string };
  const { data: installments = [] } =
    useInstallmentControllerGetInstallmentsQuery({
      id: assetId,
    });

  const { data: asset } = useAssetControllerGetAssetQuery({
    id: assetId,
  });
  return (
    <DashboardTemplate>
      <Container
        maxWidth="sm"
        sx={{ border: "1px solid #333", py: 2, borderRadius: 5 }}
      >
        <img
          src="/images/blog.jpg"
          alt=""
          style={{
            width: "100%",
            margin: "auto",
            display: "block",
            borderRadius: 10,
          }}
        />
        <Typography sx={{ fontSize: "30px", fontWeight: "bold", my: "30px" }}>
          قرارداد حکمت رضوی
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" spacing={3}>
            <Typography sx={{ fontSize: "19px", fontWeight: "500" }}>
              تاریخ خرید:
            </Typography>
            <Typography sx={{ fontSize: "19px", fontWeight: "500" }}>
              {formatDate({ date: asset?.createAt })}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography sx={{ fontSize: "19px", fontWeight: "500" }}>
              مقدار متراژ:
            </Typography>
            <Typography sx={{ fontSize: "19px", fontWeight: "500" }}>
              {asset?.size} متر
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography sx={{ fontSize: "19px", fontWeight: "500" }}>
              پرداخت:
            </Typography>
            <Typography sx={{ fontSize: "19px", fontWeight: "500" }}>
              {asset?.paymentMode === "IN_CASH" ? "نقدی" : "قسطی"}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            {[...installments].reverse().map((installment) => (
              <InstallmentItem installment={installment} />
            ))}
          </Stack>
          <Button sx={{ fontSize: "20px", mt: 2 }}>
            دانلود قرارداد / پیش‌قرارداد
          </Button>
        </Stack>
      </Container>
    </DashboardTemplate>
  );
};

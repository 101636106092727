import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const FacebookIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.2525 12.0056H13.2901C13.2901 15.1408 13.2901 19 13.2901 19H10.3823C10.3823 19 10.3823 15.1782 10.3823 12.0056H9V9.53354H10.3823V7.93458C10.3823 6.78942 10.9264 5 13.3168 5L15.4716 5.00826V7.40791C15.4716 7.40791 14.1622 7.40791 13.9077 7.40791C13.6531 7.40791 13.2911 7.53521 13.2911 8.08131V9.53403H15.5066L15.2525 12.0056Z"
        fill="white"
      />
      <mask
        id="mask0_23_103"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="9"
        y="5"
        width="7"
        height="14"
      >
        <path
          d="M15.2525 12.0056H13.2901C13.2901 15.1408 13.2901 19 13.2901 19H10.3823C10.3823 19 10.3823 15.1782 10.3823 12.0056H9V9.53354H10.3823V7.93458C10.3823 6.78942 10.9264 5 13.3168 5L15.4716 5.00826V7.40791C15.4716 7.40791 14.1622 7.40791 13.9077 7.40791C13.6531 7.40791 13.2911 7.53521 13.2911 8.08131V9.53403H15.5066L15.2525 12.0056Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_23_103)">
        <rect width="24" height="24" fill="white" />
      </g>
    </SvgIcon>
  );
};

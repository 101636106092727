import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  direction: "rtl",
  palette: {
    primary: { main: "#4B9461" },
    error: { main: "#F83232" },
    warning: {
      main: "#FFC700",
    },
    info: {
      main: "#00C2FF",
    },
  },
  typography: {
    fontFamily: "iransansxv",
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
          border: "2px solid #41683B",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
        paperFullScreen: {
          borderRadius: 0,
          border: "unset",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      },
    },
  },
});

import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";

export const LandingCard: FC<{
  direction: "row" | "column" | "row-reverse";
  src: string;
  title: string;
  content?: string;
  items?: string[];
}> = ({ direction, src, title, content = "", items = [] }) => {
  return (
    <Paper
      sx={{
        p: 3,
        background: "#ffff",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "10px",
        minHeight: "100%",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: direction }}
        spacing={2}
        justifyContent={direction !== "row" ? "space-around" : ""}
      >
        <Box
          className="image-container"
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <img
            src={src}
            alt="img"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Stack spacing={1} justifyContent="space-around">
          <Typography
            fontSize={{ md: 20, sm: 20, xs: 20 }}
            fontWeight={750}
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography fontSize={{ md: 20, sm: 16, xs: 20 }} textAlign="justify">
            {content}
          </Typography>
          <Stack>
            {items.map((item) => (
              <Typography
                fontSize={{ md: 20, sm: 15, xs: 20 }}
                textAlign="justify"
              >
                {item}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

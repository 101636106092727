import { Stack, Typography } from "@mui/material";
import { FC } from "react";

export const FactorItem: FC<{ items: string[]; color?: string }> = ({
  items,
  color,
}) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      sx={{ borderRadius: 2, px: 3, py: 1, background: color }}
    >
      {items.map((item) => (
        <Typography>{item}</Typography>
      ))}
    </Stack>
  );
};

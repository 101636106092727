import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMeetingControllerCreateMeetingMutation } from "src/app/services/generated-api";
import { Call } from "../atoms/icons/Call";
import { IncomeCall } from "../atoms/icons/IncomeCall";
import { ProfileCircle } from "../atoms/icons/ProfileCircle";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { e2p, p2e } from "src/utils/convertNumber";

export const Counsel = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [createMeeting, { isLoading }] =
    useMeetingControllerCreateMeetingMutation();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
    },
    validationSchema: yup.object({
      fullName: yup
        .string()
        .required("لطفا نام و نام خانوادگی خود را وارد کنید.")
        .test(
          "one-space-two-chars-each-side",
          "وارد کردن نام و نام خانوادگی به صورت کامل الزامی میباشد.",
          (value) => {
            if (!value) return false;

            const parts = value.split(" ");

            return parts.length === 2 && parts.every((part) => part.length > 2);
          }
        ),
      phoneNumber: yup
        .string()
        .required("وارد کردن شماره تلفن الزامی می‌باشد.")
        .matches(/^\d+$/, "شماره تلفن باید عدد باشد.")
        .matches(/^09/, "شماره تلفن باید با ۰۹ شروع شود.")
        .matches(/\d{11}$/, "شماره تلفن باید ۱۱ رقم باشد."),
    }),
    onSubmit: (values) => {
      const splittedName = values.fullName.split(" ");
      createMeeting({
        createMeetingDto: {
          firstName: splittedName[0],
          lastName: splittedName.slice(1).join(" "),
          phoneNumber: values.phoneNumber,
        },
      })
        .unwrap()
        .then(() =>
          toast.success(
            "قرار ملاقات ایجاد شد. با شما در کوتاه‌ترین زمان ممکن تماس خواهیم گرفت."
          )
        )
        .catch(() => toast.error("مشکلی پیش آمده، لطفا دوباره تلاش کنید."));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box id="Counsel" sx={{ px: { md: "50px" } }}>
        <Typography sx={{ fontSize: "1.5em", py: "20px", fontWeight: "500" }}>
          نیاز به مشاوره دارید؟
        </Typography>
        <Typography sx={{ mb: "30px", fontSize: "19px" }}>
          شماره خود را وارد کنید. مشاوران ما با شما تماس خواهند گرفت.
        </Typography>
        <Box>
          {isMobile ? (
            <>
              <TextField
                placeholder="نام و نام خانوادگی"
                sx={{
                  color: "#808080",
                }}
                InputProps={{ disableUnderline: true }}
                {...formik.getFieldProps("fullName")}
                error={Boolean(
                  formik.touched.fullName && !!formik.errors.fullName
                )}
                helperText={formik.touched.fullName && formik.errors.fullName}
                fullWidth
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ my: 3, width: "100%" }}
                spacing={2}
              >
                <TextField
                  placeholder="+98 910 49 46 036"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Call />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ direction: "rtl", color: "#808080" }}
                  {...formik.getFieldProps("phoneNumber")}
                  value={e2p(formik.values.phoneNumber)}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "phoneNumber",
                      p2e(e.target.value, true)
                    );
                  }}
                  error={Boolean(
                    formik.touched.phoneNumber && !!formik.errors.phoneNumber
                  )}
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
                <LoadingButton
                  variant="contained"
                  sx={{
                    width: "30%",
                    borderRadius: "15px",
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                    fontSize: "17px",
                  }}
                  loading={isLoading}
                >
                  تایید
                </LoadingButton>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={10}
              >
                <TextField
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ProfileCircle sx={{ fontSize: "40px" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="نام و نام خانوادگی"
                  {...formik.getFieldProps("fullName")}
                  error={Boolean(
                    formik.touched.fullName && !!formik.errors.fullName
                  )}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    },
                  }}
                />
                <TextField
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IncomeCall sx={{ fontSize: "35px" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="شماره تلفن"
                  {...formik.getFieldProps("phoneNumber")}
                  value={e2p(formik.values.phoneNumber)}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "phoneNumber",
                      p2e(e.target.value, true)
                    );
                  }}
                  error={Boolean(
                    formik.touched.phoneNumber && !!formik.errors.phoneNumber
                  )}
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    },
                  }}
                />
              </Stack>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  variant="contained"
                  sx={{
                    width: "20%",
                    fontSize: "25px",
                    fontWeight: "700",
                    borderRadius: "15px",
                    my: "30px",
                  }}
                  type="submit"
                  loading={isLoading}
                >
                  تایید
                </LoadingButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};

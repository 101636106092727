import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { MyAssets } from "src/components/molecules/MyAssets";
import { PurchaseButton } from "src/components/atoms/PurchaseButton";
import { WalletInfo } from "src/components/molecules/Wallet";
import { DashboardTemplate } from "src/components/templates/Dashboard";
import { TransactionInfo } from "src/components/molecules/MyTransaction";
import { ChargeWallet } from "src/components/molecules/ChargeWallet";
import { Wallet } from "src/components/atoms/icons/Wallet";
import { useState } from "react";

export const UserPanel = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DashboardTemplate>
      <Stack spacing={3} sx={{ px: "40px" }}>
        <Typography sx={{ fontSize: "30px", fontWeight: "bold", px: "30px" }}>
          پنل کاربری
        </Typography>
        <Box>
          <Grid container spacing={15}>
            <Grid item xs={12} sm={12} md={6} order={{ md: 1 }}>
              <WalletInfo />
            </Grid>
            <Grid
              item
              xs={false}
              sm={false}
              md={6}
              order={{ md: 2 }}
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              <Stack spacing={3}>
                <Stack direction="row" spacing={1}>
                  <Wallet sx={{ fontSize: { md: "30px " } }} />
                  <Typography sx={{ fontSize: "25px", fontWeight: "500" }}>
                    شارژ کیف پول
                  </Typography>
                </Stack>
                <ChargeWallet handleClose={handleClose} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "100%",
            fontSize: "25px",
            borderRadius: "15px",
            boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)",
            display: { xs: "block", md: "none" },
          }}
        >
          شارژ کیف پول
        </Button>
        <MyAssets />
        <TransactionInfo />
      </Stack>
      <PurchaseButton stickyMode />
    </DashboardTemplate>
  );
};

import { Box, Button, Stack, Typography } from "@mui/material";
import { formatPrice } from "src/utils/price";
import { Calendar } from "../atoms/icons/Calendar";
import { Clock } from "../atoms/icons/Clock";
import { Wallet } from "../atoms/icons/Wallet";
import { TransactionRo } from "src/app/services/generated-api";
import { formatDate } from "src/utils/dateFormatter";
import { e2p } from "src/utils/convertNumber";

interface TransactionCardProps {
  transaction: TransactionRo;
}

export const TransactionCard = ({ transaction }: TransactionCardProps) => {
  const date = new Date(transaction.createdAt);
  return (
    <Stack
      sx={{
        border: "1px solid #E1E2E4",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: "10px",
        p: "30px",
      }}
      spacing={2}
    >
      <Typography sx={{ fontSize: "22px", fontWeight: "500", mb: "10px" }}>
        پرداخت
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Wallet sx={{ fontSize: "27px" }} />
          <Typography sx={{ fontSize: "18px" }}>شارژ کیف پول :</Typography>
        </Stack>
        <Typography sx={{ fontSize: "17px" }}>
          {e2p(formatPrice(transaction.amount, true))} تومان
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Calendar sx={{ fontSize: "27px" }} />
          <Typography sx={{ fontSize: "18px" }}>تاریخ خرید :</Typography>
        </Stack>
        <Typography sx={{ fontSize: "17px" }}>
          {formatDate({ date, format: "YYYY/MM/DD" })}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Clock sx={{ fontSize: "27px" }} />
          <Typography sx={{ fontSize: "18px" }}>زمان خرید :</Typography>
        </Stack>
        <Typography sx={{ fontSize: "17px" }}>
          {formatDate({ date, format: "HH:mm" })}
        </Typography>
      </Stack>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          sx={{
            fontSize: "18px",
            borderRadius: "12px",
            boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)",
          }}
        >
          دریافت رسید
        </Button>
      </Box>
    </Stack>
  );
};

import { Box, Button, Stack, Typography } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  LoginRo,
  useAuthControllerLoginMutation,
  useAuthControllerSendOtpMutation,
} from "src/app/services/generated-api";
import { LoadingButton } from "@mui/lab";
import { ContentPropsType } from "../constants";
import { CodeInput } from "src/components/atoms/CodeInput";
import { toast } from "react-toastify";
import { canBuyAsset } from "src/helpers/user";
import { e2p } from "src/utils/convertNumber";

export const AuthCode: FC<ContentPropsType> = ({
  changePage,
  phoneNumber,
  handleCloseDialog,
}) => {
  const [codeTextFields, setCodeTextFields] = useState<(number | undefined)[]>([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  const inputs = useRef<(HTMLInputElement | null)[]>([
    null,
    null,
    null,
    null,
    null,
  ]);

  const [remaining, setRemaining] = useState(60);

  const [sendOtp, { isLoading: sendOtpLoading }] =
    useAuthControllerSendOtpMutation();
  const [login, { isLoading: submitLogin }] = useAuthControllerLoginMutation();

  const resend = () => {
    sendOtp({ phoneNumberDto: { phoneNumber } })
      .unwrap()
      .then(() => {
        changePage(1);
      });
  };

  const submit = useCallback(
    (newCodeTextFields: (number | undefined)[]) => {
      const isFilled = newCodeTextFields.every((item) => item !== undefined);
      if (!isFilled) return;

      const code = newCodeTextFields.map((item) => item?.toString()).join("");
      login({ loginDto: { phoneNumber, OTPCode: code } })
        .unwrap()
        .then((res: LoginRo) => {
          toast.success("خوش آمدید!");
          if (canBuyAsset(res.user)) {
            handleCloseDialog();
            return;
          }
          changePage(2);
        })
        .catch((err) => toast.error(err.data));
    },
    [changePage, handleCloseDialog, login, phoneNumber]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining((prev) => Math.max(0, prev - 1));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const focusInput = (index: number) => {
    const elem = inputs.current[index];
    if (elem === null) return;
    elem.selectionStart = 2;
    elem.selectionEnd = 2;
    elem.focus();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography sx={{ fontSize: "2.2em", mb: "25px" }}>رمز عبور</Typography>
        <span>
          <Typography>
            کد پنج رقمی به شماره {e2p(phoneNumber)} ارسال شده است.
          </Typography>
          <Typography>لطفا آن را وارد کنید.</Typography>
          <Button onClick={() => changePage(0)}> “تصحیح شماره”</Button>
        </span>
        <Stack
          direction="row-reverse"
          my={3}
          spacing={2}
          justifyContent="space-between"
          width="100%"
        >
          {codeTextFields.map((codeTextField, index) => (
            <CodeInput
              loading={submitLogin}
              autoFocus={index === 0}
              ref={(el) => (inputs.current[index] = el)}
              key={index}
              goNext={() => {
                if (index < 4) {
                  focusInput(index + 1);
                }
              }}
              goBack={() => {
                if (index > 0) {
                  focusInput(index - 1);
                }
              }}
              value={codeTextField}
              setValue={(value) => {
                const newCodeTextFields = codeTextFields.map(
                  (codeTextField, idx) =>
                    index === idx ? value : codeTextField
                );
                setCodeTextFields(newCodeTextFields);
                if (index >= 4) {
                  submit(newCodeTextFields);
                }
              }}
            />
          ))}
        </Stack>
        <LoadingButton
          variant="contained"
          sx={{ width: "100%", borderRadius: "10px", fontSize: "1.5em" }}
          onClick={() => submit(codeTextFields)}
          loading={submitLogin}
        >
          تایید
        </LoadingButton>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="center" mt="20px">
        {remaining <= 0 ? (
          <LoadingButton
            loading={sendOtpLoading}
            startIcon={<ReplayIcon />}
            onClick={resend}
          >
            ارسال مجدد
          </LoadingButton>
        ) : (
          <Typography sx={{ color: "#535353" }}>
            {remaining} ثانیه تا ارسال مجدد رمز عبور
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

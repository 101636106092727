import { Typography } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Expand } from "../../atoms/icons/Expand";

const questions = [
  {
    panel: "panel1",
    question: "فاصله محل پروژه از شهرها چگونه است؟",
    answer:
      "کمتر از 1 ساعت از شهر تهران و شهر قزوین و کمتر از 20 دقیقه از شهر کرج.",
  },
  {
    panel: "panel2",
    question: "دسترسی محدوده چگونه است؟",
    answer:
      "طبق طرح تفصیلی، یک بلوار عریض 45 متری از اتوبان کرج قزوین به منظور دسترسی مستقیم و اختصاصی به این محدوده احداث خواهد شد.",
  },
  {
    panel: "panel3",
    question: "تراکم ساخت محدوده چقدر است؟",
    answer:
      "بر اساس طرح تفصیلی، این منطقه دارای قطعات 200 متری با مجوز ساخت 2 طبقه بر روی پیلوت با 60% پیشروی میباشد که جمعا معادل 240 متر بنا در 2 طبقه میشود.",
  },
  {
    panel: "panel4",
    question: "آیا زمین‌ها سند تک برگ دارند؟",
    answer:
      "تمامی قطعات پس از جانمایی و تفکیک دارای سند مالکیت تک برگ خواهند شد.",
  },
  {
    panel: "panel5",
    question: "آیا اسناد مادر اصالت دارند؟ مشکل حقوقی ندارند؟",
    answer:
      "تمامی اسناد مادر، به نام شخصی بوده و جزو منابع طبیعی و ... نمیباشد و معارضی ندارد و گردش ثبتی اسناد کاملا استعلام شده است.",
  },
  {
    panel: "panel6",
    question: "هولدینگ مهر رضوی چه نوع معماری‌ای برای ساخت در نظر گرفته است؟",
    answer:
      "ما به عنوان مجری پروژه بنا داریم تا با اتکا به دانش معماری روز و منطبق با طبیعت منطقه، معماری منحصر به فرد و متمایزی برای شهر ارائه دهیم. یک معماری برگرفته از طبیعت.",
  },
  {
    panel: "panel7",
    question: "فضای سبز پروژه به چه شکل خواهد بود؟",
    answer:
      "در حواشی شرق و غرب شهر، دو فضای سبز گسترده احداث خواهد شد که به طراوت و شادابی شهر می‌افزاید.",
  },
  {
    panel: "panel8",
    question: "آیا امکان رویت اسناد وجود دارد؟",
    answer:
      "بله، تمام اسناد و مدارک مرتبط با پروژه در دفاتر مربوطه موجود هستند و سرمایه گذاران می‌توانند برای مشاهده آنها به دفاتر مراجعه کرده و در صورت نیاز استعلام نمایند.",
  },
  {
    panel: "panel9",
    question: "آیا می‌توان زمین‌ها را به صورت آنلاین خریداری کرد؟",
    answer:
      "بله، این امکان وجود دارد تا در صورت تمایل بدون خروج از منزل و به صورت آنلاین سرمایه گذاری و خرید انجام دهید.",
  },
  {
    panel: "panel10",
    question: "کمترین سرمایه مورد نیاز برای مشارکت چقدر است؟",
    answer:
      "خریداران با داشتن حداقل 5 میلیون تومان، امکان خرید زمین و سرمایه گذاری بر روی پروژه را دارند.",
  },
  {
    panel: "panel11",
    question: "آیا این محدوده اجازه ساخت مسکونی دارد؟",
    answer:
      "با ابلاغ طرح تفصیلی توسط شهرداری ...... در تاریخ ......، این محدوده اکنون در بافت مسکونی قرار گرفته و قابل استعلام است.",
  },
  {
    panel: "panel12",
    question: "آیا ممکن است بخشی از زمین را خریداری کنیم؟",
    answer:
      "بله، سرمایه گذاران این امکان را دارند تا اعشاری از یک یا چند قطعه را خریداری کنند.",
  },
];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "20px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  overflow: "hidden",
  backgroundColor: "white",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Expand sx={{ fontSize: "2rem", color: "transparent" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  px: 5,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    "& path": {
      stroke: "white",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "&.Mui-expanded": {
    backgroundColor: "#4B946180",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const QAText = () => {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      {questions.map((question, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `${question.panel}`}
            onChange={handleChange(`${question.panel}`)}
            sx={{ my: { xs: "15px", sm: "20px" } }}
          >
            <AccordionSummary id={`${question.panel}d-header`}>
              <Typography sx={{ fontSize: { md: "20px" } }}>
                {question.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{question.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

import {
  Box,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { FC, useEffect, useState } from "react";
import { ContentPropsType } from "../constants";
import { ProfileUser } from "src/components/atoms/icons/profileIcons/ProfileUser";
import { ProfileIDCode } from "src/components/atoms/icons/profileIcons/ProfileIDCode";
import { ProfileEmail } from "src/components/atoms/icons/profileIcons/ProfileEmail";
import { ProfilePhone } from "src/components/atoms/icons/profileIcons/ProfilePhone";
import { ProfileLocation } from "src/components/atoms/icons/profileIcons/ProfileLocation";
import { ProfileAddress } from "src/components/atoms/icons/profileIcons/ProfileAddress";
import { ProfileRef } from "src/components/atoms/icons/profileIcons/ProfileRef";
import { AcceptTerms } from "src/components/molecules/AcceptTerms";
import {
  useUserControllerGetProfileQuery,
  useUserControllerUpdateUserMutation,
} from "src/app/services/generated-api";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { e2p, p2e } from "src/utils/convertNumber";

export const ProfileContent: FC<ContentPropsType> = ({
  changePage,
  handleCloseDialog,
}) => {
  const { data: user } = useUserControllerGetProfileQuery();

  const [updateUser, { isLoading }] = useUserControllerUpdateUserMutation();

  let userSchema = yup.object({
    fullName: yup
      .string()
      .required("لطفا نام و نام خانوادگی خود را وارد کنید.")
      .test(
        "one-space-two-chars-each-side",
        "وارد کردن نام و نام خانوادگی به صورت کامل الزامی میباشد.",
        (value) => {
          if (!value) return false;

          const parts = value.split(" ");

          return parts.length === 2 && parts.every((part) => part.length > 2);
        }
      ),
    nationalCode: yup
      .string()
      .matches(/^\d+$/, "کد ملی باید عدد باشد.")
      .length(10, "کد ملی باید ۱۰ رقم باشد")
      .required("وارد کردن کد ملی الزامی می‌باشد.")
      .test("nationalCode", "نامعتبر", (value) => {
        let sum = 0,
          lastDigit,
          remainder;

        for (let i = 0; i < 9; i++) sum += +value[i] * (10 - i);

        remainder = sum % 11;
        lastDigit = remainder < 2 ? remainder : 11 - remainder;

        return +value[9] === lastDigit;
      }),
    email: yup.string().email("ایمیل صحیح نمی‌باشد."),
    postalCode: yup
      .string()
      .length(10, "کد پستی باید ۱۰ رقم باشد.")
      .required("وارد کردن کدپستی الزامی می‌باشد."),
    address: yup.string().required("لطفا آدرس خود را وارد کنید."),
    referralName: yup.string(),
    termsAndConditions: yup.boolean().oneOf([true], "!!!"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      nationalCode: "",
      email: "",
      postalCode: "",
      address: "",
      referralName: "",
      termsAndConditions: false,
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      const { fullName, ...data } = values;
      const splittedName = fullName.split(" ");

      updateUser({
        userUpdateDto: {
          firstName: splittedName[0],
          lastName: splittedName.slice(1).join(" "),
          ...data,
        },
      })
        .unwrap()
        .then(() => {
          handleCloseDialog();
        })
        .catch((err) => {
          console.log(err.data);
          toast.error(err.data);
        });
    },
  });

  useEffect(() => {
    if (!user) {
      changePage(0);
      return;
    }

    formik.setValues({
      fullName: user.firstName ? user.firstName + " " + user.lastName : "",
      nationalCode: user.nationalCode || "",
      email: user.email || "",
      postalCode: user.postalCode || "",
      address: user.address || "",
      referralName: user.referralName || "",
      termsAndConditions: user.termsAndConditions,
    });
  }, [changePage, handleCloseDialog, user]);

  const [termsOpen, setTermsOpen] = useState(false);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ maxWidth: "100%", justifyContent: "center", mb: 1 }}>
          <Typography sx={{ fontSize: "1.8em", mb: "20px" }}>
            پروفایل
          </Typography>
          <Stack spacing={2} margin={1}>
            <Typography>نام / نام خانوادگی</Typography>
            <TextField
              {...formik.getFieldProps("fullName")}
              error={Boolean(formik.errors.fullName && formik.touched.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfileUser />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Typography>کد ملی</Typography>
            <TextField
              {...formik.getFieldProps("nationalCode")}
              value={e2p(formik.values.nationalCode)}
              onChange={(e) => {
                formik.setFieldValue("nationalCode", p2e(e.target.value, true));
              }}
              error={Boolean(
                formik.touched.nationalCode && !!formik.errors.nationalCode
              )}
              helperText={
                formik.touched.nationalCode && formik.errors.nationalCode
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfileIDCode />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Stack direction="row" alignItems="end" spacing={1}>
              <Typography>ایمیل</Typography>
              <Typography variant="caption">"اختیاری"</Typography>
            </Stack>
            <TextField
              {...formik.getFieldProps("email")}
              error={Boolean(formik.touched.email && !!formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfileEmail />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Typography>شماره تلفن</Typography>
            <TextField
              disabled
              value={user?.phoneNumber && e2p(user.phoneNumber)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfilePhone />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Typography>کدپستی</Typography>
            <TextField
              {...formik.getFieldProps("postalCode")}
              value={e2p(formik.values.postalCode)}
              onChange={(e) => {
                formik.setFieldValue("postalCode", p2e(e.target.value, true));
              }}
              error={Boolean(
                formik.touched.postalCode && !!formik.errors.postalCode
              )}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfileLocation />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Typography>آدرس</Typography>
            <TextField
              {...formik.getFieldProps("address")}
              error={Boolean(formik.touched.address && !!formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfileAddress />
                  </InputAdornment>
                ),
              }}
              multiline
              fullWidth
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Stack direction="row" alignItems="end" spacing={1}>
              <Typography>نام و نام خانوادگی معرف</Typography>
              <Typography variant="caption">"اختیاری"</Typography>
            </Stack>
            <TextField
              {...formik.getFieldProps("referralName")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProfileRef />
                  </InputAdornment>
                ),
              }}
              sx={{
                border: "1px solid #41683B",
                borderRadius: "8px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
          </Stack>
          {!user?.termsAndConditions && (
            <FormControlLabel
              control={<Checkbox checked={formik.values.termsAndConditions} />}
              label="شرایط، قوانین و مقررات زیر را مطالعه کردم و با شرایط موافق هستم."
              sx={{ my: "5px" }}
              onClick={() => {
                if (formik.values.termsAndConditions) {
                  formik.setFieldValue("termsAndConditions", false);
                } else {
                  setTermsOpen(true);
                }
              }}
            />
          )}

          <LoadingButton
            variant="contained"
            sx={{
              borderRadius: "8px",
              mt: "20px",
              fontSize: "1.3em",
            }}
            fullWidth
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
            loading={isLoading}
          >
            تایید
          </LoadingButton>
        </Box>
      </form>
      {termsOpen && (
        <AcceptTerms
          open={termsOpen}
          onAccept={() => {
            formik.setFieldValue("termsAndConditions", true);
          }}
          handleClose={() => {
            setTermsOpen(false);
          }}
        />
      )}
    </>
  );
};

import { Check, CheckRounded } from "@mui/icons-material";
import {
  Button,
  Dialog,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export const ErrorDialog: FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobile}
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { minHeight: "50vh" } }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          height: "100%",
          p: 6,
        }}
        spacing={3}
      >
        <img src="/images/close.png" alt="error" style={{ width: "50%" }} />
        <Typography variant="h5" fontWeight={600} mb={3}>
          پرداخت ناموفق
        </Typography>
        <Typography sx={{ lineHeight: "10px" }}>
          پرداخت شما با موفقیت انجام نشد.
        </Typography>
        <Typography sx={{ lineHeight: "10px", mb: 1 }}>
          ساعاتی بعد دوباره امتحان کنید.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            handleClose();
            navigate("/");
          }}
        >
          تایید
        </Button>
      </Stack>
    </Dialog>
  );
};

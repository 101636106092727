import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AssetRo } from "src/app/services/generated-api";
import { e2p } from "src/utils/convertNumber";
import { formatDate } from "src/utils/dateFormatter";

interface AssetCardProps {
  asset: AssetRo;
}

export const AssetCard = ({ asset }: AssetCardProps) => {
  return (
    <Stack
      direction={{ xs: "row", md: "column" }}
      justifyContent="space-between"
      sx={{
        border: { xs: "1px solid #E1E2E4", md: "2px solid #41683B" },
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: "10px",
        p: { xs: 3, md: 4 },
        width: "100%",
        "& img": {
          width: { xs: "40%", sm: "45%", md: "100%" },
        },
      }}
      spacing={2}
    >
      <img src="/images/blog.jpg" alt="" style={{ borderRadius: 5 }} />
      <Stack justifyContent="space-between" width="100%" spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: { sm: "18px", xs: 15 } }}>
            تاریخ خرید:
          </Typography>
          <Typography sx={{ fontSize: { sm: "18px", xs: 15 } }}>
            {formatDate({ date: asset.createAt })}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: { sm: "18px", xs: 15 } }}>
            مقدار متراژ:
          </Typography>
          <Typography sx={{ fontSize: { sm: "18px", xs: 15 } }}>
            {e2p(asset.size)} متر
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: { sm: "18px", xs: 15 } }}>
            پرداخت:
          </Typography>
          <Typography sx={{ fontSize: { sm: "18px", xs: 15 } }}>
            {asset.paymentMode === "IN_CASH" ? "نقدی" : "قسطی"}
          </Typography>
        </Stack>
        <Box
          sx={{
            textAlign: { xs: "right", md: "center" },
            pt: { sm: 3, xs: 0 },
          }}
        >
          <Button
            component={Link}
            to={"/assets/" + asset.id}
            variant="contained"
            sx={{
              fontSize: { xs: "18px", md: "25px" },
              borderRadius: "10px",
              width: { md: "100%" },
              py: 0.1,
              px: 1,
            }}
            size="small"
          >
            بیشتر
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const TwitterIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5076 6.49288L18.4704 6.46808C17.7014 5.73634 16.7961 5.38907 15.7543 5.43868L15.7171 5.36426L15.7419 5.35186C16.6844 5.14102 17.2426 4.91778 17.4162 4.66973C17.4658 4.47129 17.4038 4.35967 17.1929 4.33486C16.7217 4.39688 16.2752 4.4961 15.8907 4.65733C16.3868 4.33486 16.5728 4.11162 16.4612 4C15.9775 4.0124 15.4442 4.26045 14.8985 4.75655C15.0969 4.40928 15.1838 4.21084 15.1341 4.18604C14.8613 4.35967 14.638 4.55811 14.4396 4.76895C14.0179 5.24024 13.6831 5.67432 13.4226 6.0712L13.4102 6.09601C12.7529 7.17501 12.2816 8.25402 12.0087 9.35784L11.9095 9.44465L11.8847 9.45706C11.4878 8.96096 11.0041 8.53928 10.4336 8.20441C9.7639 7.75793 8.97015 7.34865 8.05237 6.93937C7.06018 6.41847 6.04318 5.99679 5.02619 5.66192C5.01378 6.81535 5.58429 7.72072 6.68811 8.39045V8.40285C6.30363 8.40285 5.91916 8.46486 5.54709 8.57649C5.6215 9.64309 6.37805 10.3748 7.80432 10.7717L7.79192 10.7965C7.23381 10.7593 6.77492 10.9577 6.41526 11.367C6.88655 12.2848 7.71751 12.7189 8.92054 12.6941C8.68489 12.8181 8.49886 12.9421 8.37484 13.091C8.15159 13.3266 8.07718 13.5995 8.15159 13.9095C8.41204 14.3808 8.87093 14.5917 9.55306 14.5544L9.59027 14.6041L9.57787 14.6289C8.39964 15.8443 6.97336 16.39 5.31144 16.2784L5.28664 16.2908C4.26964 16.2784 3.17823 15.7947 2 14.8273C3.17823 16.5264 4.75333 17.7543 6.70051 18.5356C8.92054 19.2674 11.153 19.3294 13.373 18.6968H13.4102C15.5682 18.0767 17.3914 16.7869 18.9045 14.8521C19.599 13.8475 20.0331 12.8801 20.2067 11.9499C21.3353 11.9871 22.1415 11.6647 22.65 10.9701L22.6376 10.9453C22.2531 11.0818 21.5214 11.0446 20.4424 10.8213V10.6973C21.633 10.5609 22.3399 10.1764 22.5632 9.54387C21.7322 9.86634 20.9137 9.87874 20.1075 9.56868C19.9587 8.45246 19.4254 7.42306 18.5076 6.49288"
        fill="white"
      />
      <mask
        id="mask0_23_108"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="21"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5076 6.49288L18.4704 6.46808C17.7014 5.73634 16.7961 5.38907 15.7543 5.43868L15.7171 5.36426L15.7419 5.35186C16.6844 5.14102 17.2426 4.91778 17.4162 4.66973C17.4658 4.47129 17.4038 4.35967 17.1929 4.33486C16.7217 4.39688 16.2752 4.4961 15.8907 4.65733C16.3868 4.33486 16.5728 4.11162 16.4612 4C15.9775 4.0124 15.4442 4.26045 14.8985 4.75655C15.0969 4.40928 15.1838 4.21084 15.1341 4.18604C14.8613 4.35967 14.638 4.55811 14.4396 4.76895C14.0179 5.24024 13.6831 5.67432 13.4226 6.0712L13.4102 6.09601C12.7529 7.17501 12.2816 8.25402 12.0087 9.35784L11.9095 9.44465L11.8847 9.45706C11.4878 8.96096 11.0041 8.53928 10.4336 8.20441C9.7639 7.75793 8.97015 7.34865 8.05237 6.93937C7.06018 6.41847 6.04318 5.99679 5.02619 5.66192C5.01378 6.81535 5.58429 7.72072 6.68811 8.39045V8.40285C6.30363 8.40285 5.91916 8.46486 5.54709 8.57649C5.6215 9.64309 6.37805 10.3748 7.80432 10.7717L7.79192 10.7965C7.23381 10.7593 6.77492 10.9577 6.41526 11.367C6.88655 12.2848 7.71751 12.7189 8.92054 12.6941C8.68489 12.8181 8.49886 12.9421 8.37484 13.091C8.15159 13.3266 8.07718 13.5995 8.15159 13.9095C8.41204 14.3808 8.87093 14.5917 9.55306 14.5544L9.59027 14.6041L9.57787 14.6289C8.39964 15.8443 6.97336 16.39 5.31144 16.2784L5.28664 16.2908C4.26964 16.2784 3.17823 15.7947 2 14.8273C3.17823 16.5264 4.75333 17.7543 6.70051 18.5356C8.92054 19.2674 11.153 19.3294 13.373 18.6968H13.4102C15.5682 18.0767 17.3914 16.7869 18.9045 14.8521C19.599 13.8475 20.0331 12.8801 20.2067 11.9499C21.3353 11.9871 22.1415 11.6647 22.65 10.9701L22.6376 10.9453C22.2531 11.0818 21.5214 11.0446 20.4424 10.8213V10.6973C21.633 10.5609 22.3399 10.1764 22.5632 9.54387C21.7322 9.86634 20.9137 9.87874 20.1075 9.56868C19.9587 8.45246 19.4254 7.42306 18.5076 6.49288"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_23_108)">
        <rect width="24" height="24" fill="white" />
      </g>
    </SvgIcon>
  );
};

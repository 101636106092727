import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";
import { LeftArrow } from "src/components/atoms/icons/LeftArrow";
import "src/assets/general.css";
import { buyPages } from "./constants";

export const BuyDialog: FC<{
  open: boolean;
  handleClose: () => void;
  openPaymentDialog: () => void;
  openAuthDialog: () => void;
}> = ({ open, handleClose, openPaymentDialog, openAuthDialog }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [totalAmount, setTotalAmount] = useState(10000000);
  const [isCash, setIsCash] = useState(true);

  const ContentComponent = buyPages[pageIndex].component;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      fullScreen={isMobile}
      scroll="body"
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          pt={3}
          pb={1}
        >
          <Typography variant="h4">سرمایه‌گذاری</Typography>
          <IconButton
            onClick={() => {
              if (pageIndex === 0) {
                return handleClose();
              }
              setPageIndex(pageIndex - 1);
            }}
          >
            <LeftArrow />
          </IconButton>
        </Stack>
      </DialogTitle>
      <ContentComponent
        handleCloseDialog={handleClose}
        setPage={(pageIndex: number) => setPageIndex(pageIndex)}
        openPaymentDialog={openPaymentDialog}
        openAuthDialog={openAuthDialog}
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
        isCash={isCash}
        setIsCash={setIsCash}
      />
    </Dialog>
  );
};

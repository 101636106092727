import { Box } from "@mui/material";
import { FC, useMemo } from "react";

export const GalleryItem: FC<{
  index: number;
  currentSlide: number;
  listLength: number;
  src: string;
}> = ({ index, currentSlide, listLength, src }) => {
  const isCurrent = useMemo(
    () => index === currentSlide,
    [currentSlide, index]
  );
  const isNext = useMemo(
    () =>
      currentSlide === listLength - 1
        ? index === 0
        : currentSlide + 1 === index,
    [currentSlide, index, listLength]
  );
  const isPrev = useMemo(
    () =>
      currentSlide === 0
        ? index === listLength - 1
        : currentSlide - 1 === index,
    [currentSlide, index, listLength]
  );

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: isNext || isPrev ? 1 : isCurrent ? 2 : 0,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        transition: "z-index 0ms 250ms",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.2)",
          transition: "500ms cubic-bezier(0.17, 0.67, 0.55, 1.43)",
          opacity: isNext || isPrev ? 0.3 : isCurrent ? 1 : 0,
          transform: isPrev
            ? "translateX(-25%) scale(0.8)"
            : isNext
            ? "translateX(25%) scale(0.8)"
            : isCurrent
            ? "translateX(0%) scale(1)"
            : "scale(0)",
        }}
      >
        <img style={{ width: "100%", height: "100%" }} src={src} alt="1" />
      </Box>
    </Box>
  );
};

import {
  Box,
  Button,
  Divider,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FacebookIcon } from "src/components/atoms/icons/FacebookIcon";
import { InstaIcon } from "src/components/atoms/icons/InstaIcon";
import { TwitterIcon } from "src/components/atoms/icons/TwitterIcon";

export const Footer = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Box
      sx={{
        width: "100%",
        p: 3,
      }}
      id="Footer"
    >
      {isMobile ? (
        <>
          <Stack spacing={2}>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="/images/logo.svg"
                alt="logo"
                style={{ maxWidth: "40%", width: 150 }}
              />
            </Box>
            <Typography
              sx={{ fontSize: 16, textAlign: "center", color: "white" }}
            >
              کلیه حقوق حکمت رضوی متعلق به هلدینگ رضوی میباشد
            </Typography>
            <Stack direction="row" justifyContent="center">
              <IconButton>
                <InstaIcon sx={{ fontSize: "31px" }} />
              </IconButton>
              <IconButton>
                <FacebookIcon sx={{ fontSize: "31px" }} />
              </IconButton>
              <IconButton>
                <TwitterIcon sx={{ fontSize: "31px" }} />
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button sx={{ color: "white" }}>صفحه اصلی</Button>
              <Divider
                orientation="vertical"
                sx={{ color: "white" }}
                flexItem
                variant="middle"
              />
              <Button sx={{ color: "white" }}>درباره ما</Button>
              <Divider
                orientation="vertical"
                sx={{ color: "white" }}
                flexItem
                variant="middle"
              />
              <Button sx={{ color: "white" }}>تماس</Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack
            direction="row"
            sx={{ mb: "50px", width: "100%", px: { lg: 10 } }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ width: "30%" }}>
              <Typography sx={{ color: "white", fontSize: "22px", mb: "20px" }}>
                درباره ما
              </Typography>
              <Typography sx={{ color: "white" }}>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است
              </Typography>
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
              height="fit-content"
              width="40%"
            >
              <Button
                sx={{ color: "white", fontSize: { md: "15px", lg: "19px" } }}
              >
                صفحه اصلی
              </Button>
              <Divider
                orientation="vertical"
                sx={{ color: "white" }}
                flexItem
                variant="middle"
              />
              <Button
                sx={{ color: "white", fontSize: { md: "15px", lg: "19px" } }}
              >
                درباره ما
              </Button>
              <Divider
                orientation="vertical"
                sx={{ color: "white" }}
                flexItem
                variant="middle"
              />
              <Button
                sx={{ color: "white", fontSize: { md: "15px", lg: "19px" } }}
              >
                مشاوره رایگان
              </Button>
            </Stack>
            <Box sx={{ width: "20%" }}>
              <Box
                sx={{
                  textAlign: "center",
                  mb: "20px",
                  width: "fit-content",
                }}
              >
                <img
                  src="/images/logo.svg"
                  alt="logo"
                  style={{
                    maxWidth: "40%",
                    width: 500,
                  }}
                />
              </Box>
              <Stack
                direction="row"
                justifyContent="center"
                textAlign="center"
                width="100%"
              >
                <IconButton>
                  <InstaIcon sx={{ fontSize: "31px" }} />
                </IconButton>
                <IconButton>
                  <FacebookIcon sx={{ fontSize: "31px" }} />
                </IconButton>
                <IconButton>
                  <TwitterIcon sx={{ fontSize: "31px" }} />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          <Box sx={{ my: "15px", px: 10 }}>
            <Typography sx={{ color: "white" }}>
              تمامی حقوق برای کاربر این محصول محفوظ است.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

import {
  Box,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Dollar } from "../atoms/icons/Dollar";
import { Info } from "../atoms/icons/Info";
import { LeftArrow2 } from "../atoms/icons/LeftArrow2";
import { MenuIcon } from "../atoms/icons/MenuIcon";
import { PhoneRing } from "../atoms/icons/PhoneRing";
import { UserIcon } from "../atoms/icons/User";
import { UserSet } from "../atoms/icons/UserSet";

export const SidebarMenu: FC<{
  setOpenAuthDialog: (open: boolean) => void;
  setInitAuthPage: (initAuthPage?: number) => void;
}> = ({ setInitAuthPage, setOpenAuthDialog }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton onClick={handleClickOpen}>
        <MenuIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            padding: "50px",
            border: "2px solid #41683B",
            borderRadius: "16px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          },
        }}
        maxWidth="xs"
        fullWidth
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setOpenAuthDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText>ورود / ثبت نام</ListItemText>
            <LeftArrow2 />
          </MenuItem>
          <Divider
            sx={{
              backgroundColor: "#4B946180",
              height: "4px",
              borderRadius: "8px",
              borderBottom: "none",
            }}
          />
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            component={Link}
            to="/#Counsel"
          >
            <ListItemIcon>
              <PhoneRing />
            </ListItemIcon>
            <ListItemText>مشاوره</ListItemText>
            <LeftArrow2 />
          </MenuItem>
          <Divider
            sx={{
              backgroundColor: "#4B946180",
              height: "4px",
              borderRadius: "8px",
              borderBottom: "none",
            }}
          />
          <MenuItem
            onClick={() => {
              setOpenAuthDialog(true);
              setInitAuthPage(2);
              handleClose();
            }}
          >
            <ListItemIcon>
              <UserSet />
            </ListItemIcon>
            <ListItemText>پروفایل</ListItemText>
            <LeftArrow2 />
          </MenuItem>
          <Divider
            sx={{
              backgroundColor: "#4B946180",
              height: "4px",
              borderRadius: "8px",
              borderBottom: "none",
            }}
          />
          <MenuItem component={Link} to="/userPanel">
            <ListItemIcon>
              <Dollar />
            </ListItemIcon>
            <ListItemText>پنل کاربری</ListItemText>
            <LeftArrow2 />
          </MenuItem>
          <Divider
            sx={{
              backgroundColor: "#4B946180",
              height: "4px",
              borderRadius: "8px",
              borderBottom: "none",
            }}
          />
          <MenuItem>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText>درباره ما</ListItemText>
            <LeftArrow2 />
          </MenuItem>
        </MenuList>
      </Dialog>
    </Box>
  );
};

import { enhancedApi } from "./generated-api";

export const modifiedApi = enhancedApi.enhanceEndpoints({
  addTagTypes: ["Profile", "Asset", "Installment", "Transaction"],
  endpoints: {
    userControllerGetProfile: {
      providesTags: () => ["Profile"],
    },
    authControllerLogin: {
      invalidatesTags: () => ["Profile"],
    },

    // useUserControllerFindUsersQuery
    // useUserControllerUpdateUserMutation
    // useUserControllerGetProfileQuery
    transactionControllerChargeWallet: {
      invalidatesTags: () => ["Profile"],
    },
    transactionControllerCreatePortalTransaction: {
      invalidatesTags: () => ["Transaction"],
    },
    transactionControllerGetWalletTransactionResponse: {
      providesTags: () => ["Transaction"],
    },
    transactionControllerGetPortalTransactionResponse: {
      providesTags: () => ["Transaction"],
    },
    transactionControllerGetMyTransactions: {
      providesTags: () => ["Transaction"],
    },
    installmentControllerCreateInstallment: {
      invalidatesTags: () => ["Installment"],
    },
    installmentControllerGetInstallments: {
      providesTags: () => ["Installment"],
    },
    installmentControllerPayInstallment: {
      invalidatesTags: () => ["Installment"], //TODO: fix
    },
    installmentControllerUpdateInstallment: {
      invalidatesTags: () => ["Installment"],
    },
    installmentControllerDeleteInstallment: {
      invalidatesTags: () => ["Installment"],
    },
    installmentControllerGetNearestInstallment: {
      providesTags: () => ["Installment", "Asset"], //TODO:fix
    },
    assetControllerCreateAsset: {
      invalidatesTags: ["Asset"],
    },
    assetControllerGetAsset: {
      providesTags: () => ["Asset", "Installment"], //TODO:fix
    },
    assetControllerGetMyAssets: {
      providesTags: () => ["Asset", "Installment"], //TODO:fix
    },
    // useAssetControllerGetMyCartQuery
    // useAssetControllerMyAssetsValuationQuery
    // useAssetControllerUpdateAssetMutation
    // useAssetControllerActivateAssetMutation
    // useAssetControllerArchiveAssetMutation
    // useAssetControllerDeleteAssetMutation
    // useAssetControllerReturnAssetMutation
    assetControllerOnlineBuyAsset: {
      invalidatesTags: ["Asset", "Installment"], //TODO:fix
    },
    // useAuthControllerSendOtpMutation
    // useAuthControllerLoginMutation
    // useProjectControllerCreateProjectMutation
    // useProjectControllerGetProjectQuery
    // useProjectControllerFindProjectsQuery
    // useProjectControllerUpdateProjectMutation
    // useProjectControllerArchiveProjectMutation
    // useProjectControllerDeleteProjectMutation
    // useProjectControllerProjectProgressQuery
    // useRolesControllerReadPermissionsQuery
    // useRolesControllerCreateRoleMutation
    // useRolesControllerUpdateRoleMutation
    // useRolesControllerFindRoleQuery
    // useRolesControllerDeleteRoleMutation
    // useRolesControllerReadRoleQuery
    // useMeetingControllerCreateMeetingMutation
    // useMeetingControllerFindMeetingsQuery
  },
});

import * as yup from "yup";

import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useAuthControllerSendOtpMutation } from "src/app/services/generated-api";
import { LoadingButton } from "@mui/lab";
import { FC } from "react";
import { ContentPropsType } from "../constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { ProfilePhone } from "src/components/atoms/icons/profileIcons/ProfilePhone";
import { e2p, p2e } from "src/utils/convertNumber";

export const SignUpContent: FC<ContentPropsType> = ({
  changePage,
  setPhoneNumber,
}) => {
  const [sendOtp, { isLoading }] = useAuthControllerSendOtpMutation();

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: yup.object({
      phoneNumber: yup
        .string()
        .required("وارد کردن شماره تلفن الزامی می‌باشد.")
        .matches(/^\d+$/, "شماره تلفن باید عدد باشد.")
        .matches(/^09/, "شماره تلفن باید با ۰۹ شروع شود.")
        .matches(/\d{11}$/, "شماره تلفن باید ۱۱ رقم باشد."),
    }),
    onSubmit: (values) => {
      sendOtp({ phoneNumberDto: { phoneNumber: values.phoneNumber } })
        .unwrap()
        .then(() => {
          setPhoneNumber(values.phoneNumber);
          changePage(1);
        })
        .catch(() => {
          toast.error("دوباره تلاش کنید.");
        });
    },
  });

  const setSpace = (value: string) => {
    if (value.length > 4) {
      return value.slice(0, 4) + " " + value.slice(4);
    }
    return value;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%", pt: 3 }}>
        <Typography sx={{ fontSize: "2.2em", mb: "25px" }}>
          ورود / ثبت نام
        </Typography>
        <Typography sx={{ fontSize: "1.2em", mb: "30px" }}>
          برای ورود / ثبت نام شماره تلفن خود را وارد کنید.
        </Typography>
        <TextField
          placeholder="0910 49 46 036"
          fullWidth
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <ProfilePhone />
              </InputAdornment>
            ),
          }}
          sx={{
            direction: "rtl",
            mb: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            },
          }}
          {...formik.getFieldProps("phoneNumber")}
          value={e2p(setSpace(formik.values.phoneNumber))}
          onChange={(e) => {
            formik.setFieldValue(
              "phoneNumber",
              p2e(e.target.value.split(" ").join(""), true)
            );
          }}
          error={Boolean(
            formik.touched.phoneNumber && !!formik.errors.phoneNumber
          )}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        />
        <LoadingButton
          loading={isLoading}
          variant="contained"
          sx={{ width: "100%", borderRadius: "10px", fontSize: "1.5em" }}
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
        >
          تایید
        </LoadingButton>
      </Box>
    </form>
  );
};

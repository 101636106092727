import { api } from "./api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    userControllerFindUsers: build.query<
      UserControllerFindUsersApiResponse,
      UserControllerFindUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/find-users`,
        params: {
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          nationalCode: queryArg.nationalCode,
          phoneNumber: queryArg.phoneNumber,
          address: queryArg.address,
        },
      }),
    }),
    userControllerUpdateUser: build.mutation<
      UserControllerUpdateUserApiResponse,
      UserControllerUpdateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/update-user`,
        method: "PATCH",
        body: queryArg.userUpdateDto,
      }),
    }),
    userControllerGetProfile: build.query<
      UserControllerGetProfileApiResponse,
      UserControllerGetProfileApiArg
    >({
      query: () => ({ url: `/api/v1/users/profile` }),
    }),
    transactionControllerChargeWallet: build.mutation<
      TransactionControllerChargeWalletApiResponse,
      TransactionControllerChargeWalletApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/transactions/charge-wallet`,
        method: "POST",
        body: queryArg.walletTransactionDto,
      }),
    }),
    transactionControllerCreatePortalTransaction: build.mutation<
      TransactionControllerCreatePortalTransactionApiResponse,
      TransactionControllerCreatePortalTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/transactions/create-portal-transaction`,
        method: "POST",
        body: queryArg.portalTransactionDto,
      }),
    }),
    transactionControllerGetWalletTransactionResponse: build.query<
      TransactionControllerGetWalletTransactionResponseApiResponse,
      TransactionControllerGetWalletTransactionResponseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/transactions/get-wallet-transaction-response`,
        params: {
          trans_id: queryArg.transId,
          order_id: queryArg.orderId,
          amount: queryArg.amount,
        },
      }),
    }),
    transactionControllerGetPortalTransactionResponse: build.query<
      TransactionControllerGetPortalTransactionResponseApiResponse,
      TransactionControllerGetPortalTransactionResponseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/transactions/get-portal-transaction-response`,
        params: {
          trans_id: queryArg.transId,
          order_id: queryArg.orderId,
          amount: queryArg.amount,
        },
      }),
    }),
    transactionControllerGetMyTransactions: build.query<
      TransactionControllerGetMyTransactionsApiResponse,
      TransactionControllerGetMyTransactionsApiArg
    >({
      query: () => ({ url: `/api/v1/transactions/get-my-transactions` }),
    }),
    installmentControllerCreateInstallment: build.mutation<
      InstallmentControllerCreateInstallmentApiResponse,
      InstallmentControllerCreateInstallmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/installment/create-installment`,
        method: "POST",
        body: queryArg.createInstallmentDto,
      }),
    }),
    installmentControllerGetInstallments: build.query<
      InstallmentControllerGetInstallmentsApiResponse,
      InstallmentControllerGetInstallmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/installment/get-installments-of-asset/${queryArg.id}`,
      }),
    }),
    installmentControllerPayInstallment: build.mutation<
      InstallmentControllerPayInstallmentApiResponse,
      InstallmentControllerPayInstallmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/installment/pay-installment/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    installmentControllerUpdateInstallment: build.mutation<
      InstallmentControllerUpdateInstallmentApiResponse,
      InstallmentControllerUpdateInstallmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/installment/update-installment`,
        method: "PATCH",
        body: queryArg.updateInstallmentDto,
      }),
    }),
    installmentControllerDeleteInstallment: build.mutation<
      InstallmentControllerDeleteInstallmentApiResponse,
      InstallmentControllerDeleteInstallmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/installment/remove-Installment`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    installmentControllerGetNearestInstallment: build.query<
      InstallmentControllerGetNearestInstallmentApiResponse,
      InstallmentControllerGetNearestInstallmentApiArg
    >({
      query: () => ({ url: `/api/v1/installment/get-nearest-Installment` }),
    }),
    assetControllerCreateAsset: build.mutation<
      AssetControllerCreateAssetApiResponse,
      AssetControllerCreateAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/create-asset`,
        method: "POST",
        body: queryArg.createAssetDto,
      }),
    }),
    assetControllerGetAsset: build.query<
      AssetControllerGetAssetApiResponse,
      AssetControllerGetAssetApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/assets/get-asset/${queryArg.id}` }),
    }),
    assetControllerGetMyAssets: build.query<
      AssetControllerGetMyAssetsApiResponse,
      AssetControllerGetMyAssetsApiArg
    >({
      query: () => ({ url: `/api/v1/assets/get-my-asset` }),
    }),
    assetControllerGetMyCart: build.query<
      AssetControllerGetMyCartApiResponse,
      AssetControllerGetMyCartApiArg
    >({
      query: () => ({ url: `/api/v1/assets/get-my-cart` }),
    }),
    assetControllerMyAssetsValuation: build.query<
      AssetControllerMyAssetsValuationApiResponse,
      AssetControllerMyAssetsValuationApiArg
    >({
      query: () => ({ url: `/api/v1/assets/get-my-assets-valuation` }),
    }),
    assetControllerUpdateAsset: build.mutation<
      AssetControllerUpdateAssetApiResponse,
      AssetControllerUpdateAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/update-asset`,
        method: "PATCH",
      }),
    }),
    assetControllerActivateAsset: build.mutation<
      AssetControllerActivateAssetApiResponse,
      AssetControllerActivateAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/activate-asset/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    assetControllerArchiveAsset: build.mutation<
      AssetControllerArchiveAssetApiResponse,
      AssetControllerArchiveAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/archive-asset/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    assetControllerDeleteAsset: build.mutation<
      AssetControllerDeleteAssetApiResponse,
      AssetControllerDeleteAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/remove-asset`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    assetControllerReturnAsset: build.mutation<
      AssetControllerReturnAssetApiResponse,
      AssetControllerReturnAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/return-asset/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    assetControllerOnlineBuyAsset: build.mutation<
      AssetControllerOnlineBuyAssetApiResponse,
      AssetControllerOnlineBuyAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/online-buy-asset-transaction`,
        method: "POST",
        body: queryArg.onlineBuyAssetDto,
      }),
    }),
    authControllerSendOtp: build.mutation<
      AuthControllerSendOtpApiResponse,
      AuthControllerSendOtpApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/send-otp`,
        method: "POST",
        body: queryArg.phoneNumberDto,
      }),
    }),
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/login`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    projectControllerCreateProject: build.mutation<
      ProjectControllerCreateProjectApiResponse,
      ProjectControllerCreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/create-project`,
        method: "POST",
        body: queryArg.createProjectDto,
      }),
    }),
    projectControllerGetProject: build.query<
      ProjectControllerGetProjectApiResponse,
      ProjectControllerGetProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/get-project/${queryArg.id}`,
      }),
    }),
    projectControllerFindProjects: build.query<
      ProjectControllerFindProjectsApiResponse,
      ProjectControllerFindProjectsApiArg
    >({
      query: () => ({ url: `/api/v1/projects/get-all-projects` }),
    }),
    projectControllerUpdateProject: build.mutation<
      ProjectControllerUpdateProjectApiResponse,
      ProjectControllerUpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/update-project`,
        method: "PATCH",
        body: queryArg.updateProjectDto,
      }),
    }),
    projectControllerArchiveProject: build.mutation<
      ProjectControllerArchiveProjectApiResponse,
      ProjectControllerArchiveProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/archive-project/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    projectControllerDeleteProject: build.mutation<
      ProjectControllerDeleteProjectApiResponse,
      ProjectControllerDeleteProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/remove-project/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    projectControllerProjectProgress: build.query<
      ProjectControllerProjectProgressApiResponse,
      ProjectControllerProjectProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/projects/project-progress/${queryArg.id}`,
      }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "POST",
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "PUT",
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    meetingControllerCreateMeeting: build.mutation<
      MeetingControllerCreateMeetingApiResponse,
      MeetingControllerCreateMeetingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/book-meeting`,
        method: "POST",
        body: queryArg.createMeetingDto,
      }),
    }),
    meetingControllerFindMeetings: build.query<
      MeetingControllerFindMeetingsApiResponse,
      MeetingControllerFindMeetingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/all-meeting`,
        params: {
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          phoneNumber: queryArg.phoneNumber,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UserControllerFindUsersApiResponse = /** status 200  */ UserRo[];
export type UserControllerFindUsersApiArg = {
  firstName?: string;
  lastName?: string;
  nationalCode?: string;
  phoneNumber?: string;
  address?: string;
};
export type UserControllerUpdateUserApiResponse = /** status 200  */ UserRo;
export type UserControllerUpdateUserApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UserControllerGetProfileApiResponse = /** status 200  */ UserRo;
export type UserControllerGetProfileApiArg = void;
export type TransactionControllerChargeWalletApiResponse =
  /** status 201  */ string;
export type TransactionControllerChargeWalletApiArg = {
  walletTransactionDto: WalletTransactionDto;
};
export type TransactionControllerCreatePortalTransactionApiResponse =
  /** status 201  */ string;
export type TransactionControllerCreatePortalTransactionApiArg = {
  portalTransactionDto: PortalTransactionDto;
};
export type TransactionControllerGetWalletTransactionResponseApiResponse =
  unknown;
export type TransactionControllerGetWalletTransactionResponseApiArg = {
  transId: string;
  orderId: string;
  amount: number;
};
export type TransactionControllerGetPortalTransactionResponseApiResponse =
  unknown;
export type TransactionControllerGetPortalTransactionResponseApiArg = {
  transId: string;
  orderId: string;
  amount: number;
};
export type TransactionControllerGetMyTransactionsApiResponse =
  /** status 201  */ TransactionRo[];
export type TransactionControllerGetMyTransactionsApiArg = void;
export type InstallmentControllerCreateInstallmentApiResponse =
  /** status 201  */ InstallmentRo;
export type InstallmentControllerCreateInstallmentApiArg = {
  createInstallmentDto: CreateInstallmentDto;
};
export type InstallmentControllerGetInstallmentsApiResponse =
  /** status 201  */ InstallmentRo[];
export type InstallmentControllerGetInstallmentsApiArg = {
  id: string;
};
export type InstallmentControllerPayInstallmentApiResponse =
  /** status 201  */ InstallmentRo;
export type InstallmentControllerPayInstallmentApiArg = {
  id: string;
};
export type InstallmentControllerUpdateInstallmentApiResponse =
  /** status 201  */ InstallmentRo;
export type InstallmentControllerUpdateInstallmentApiArg = {
  updateInstallmentDto: UpdateInstallmentDto;
};
export type InstallmentControllerDeleteInstallmentApiResponse = unknown;
export type InstallmentControllerDeleteInstallmentApiArg = {
  idDto: IdDto;
};
export type InstallmentControllerGetNearestInstallmentApiResponse =
  /** status 200  */ InstallmentRo;
export type InstallmentControllerGetNearestInstallmentApiArg = void;
export type AssetControllerCreateAssetApiResponse = /** status 201  */ AssetRo;
export type AssetControllerCreateAssetApiArg = {
  createAssetDto: CreateAssetDto;
};
export type AssetControllerGetAssetApiResponse = /** status 201  */ AssetRo;
export type AssetControllerGetAssetApiArg = {
  id: string;
};
export type AssetControllerGetMyAssetsApiResponse =
  /** status 201  */ AssetRo[];
export type AssetControllerGetMyAssetsApiArg = void;
export type AssetControllerGetMyCartApiResponse = /** status 201  */ AssetRo;
export type AssetControllerGetMyCartApiArg = void;
export type AssetControllerMyAssetsValuationApiResponse =
  /** status 201  */ AssetValuationRo[];
export type AssetControllerMyAssetsValuationApiArg = void;
export type AssetControllerUpdateAssetApiResponse = /** status 201  */ AssetRo;
export type AssetControllerUpdateAssetApiArg = {
  buyer: string;
  project: string;
  size: number;
  id: string;
};
export type AssetControllerActivateAssetApiResponse = unknown;
export type AssetControllerActivateAssetApiArg = {
  id: string;
};
export type AssetControllerArchiveAssetApiResponse = unknown;
export type AssetControllerArchiveAssetApiArg = {
  id: string;
};
export type AssetControllerDeleteAssetApiResponse = unknown;
export type AssetControllerDeleteAssetApiArg = {
  idDto: IdDto;
};
export type AssetControllerReturnAssetApiResponse = unknown;
export type AssetControllerReturnAssetApiArg = {
  id: string;
};
export type AssetControllerOnlineBuyAssetApiResponse =
  /** status 201  */ AssetRo;
export type AssetControllerOnlineBuyAssetApiArg = {
  onlineBuyAssetDto: OnlineBuyAssetDto;
};
export type AuthControllerSendOtpApiResponse = unknown;
export type AuthControllerSendOtpApiArg = {
  phoneNumberDto: PhoneNumberDto;
};
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type ProjectControllerCreateProjectApiResponse =
  /** status 201  */ ProjectRo;
export type ProjectControllerCreateProjectApiArg = {
  createProjectDto: CreateProjectDto;
};
export type ProjectControllerGetProjectApiResponse =
  /** status 201  */ ProjectRo;
export type ProjectControllerGetProjectApiArg = {
  id: string;
};
export type ProjectControllerFindProjectsApiResponse =
  /** status 201  */ ProjectRo[];
export type ProjectControllerFindProjectsApiArg = void;
export type ProjectControllerUpdateProjectApiResponse =
  /** status 201  */ ProjectRo;
export type ProjectControllerUpdateProjectApiArg = {
  updateProjectDto: UpdateProjectDto;
};
export type ProjectControllerArchiveProjectApiResponse = unknown;
export type ProjectControllerArchiveProjectApiArg = {
  id: string;
};
export type ProjectControllerDeleteProjectApiResponse = unknown;
export type ProjectControllerDeleteProjectApiArg = {
  id: string;
};
export type ProjectControllerProjectProgressApiResponse = unknown;
export type ProjectControllerProjectProgressApiArg = {
  id: string;
};
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type MeetingControllerCreateMeetingApiResponse =
  /** status 201  */ MeetingRo;
export type MeetingControllerCreateMeetingApiArg = {
  createMeetingDto: CreateMeetingDto;
};
export type MeetingControllerFindMeetingsApiResponse =
  /** status 201  */ MeetingRo;
export type MeetingControllerFindMeetingsApiArg = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
};
export type UserRo = {
  id: string;
  firstName: string;
  lastName: string;
  nationalCode: string;
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  referralName: string;
  walletBalance: number;
  termsAndConditions: boolean;
  roleNames: string[];
};
export type UserUpdateDto = {
  firstName: string;
  lastName: string;
  nationalCode: string;
  email?: string;
  address: string;
  postalCode: string;
  referralName: string;
  termsAndConditions: boolean;
};
export type WalletTransactionDto = {
  amount: number;
  transactionType: "PAYMENT_PORTAL" | "OFFLINE_PAYMENT" | "INCREASE_WALLET";
  description: string;
};
export type InstallmentRo = {
  id: string;
  asset: string;
  dueDate: string;
  paidStatus: boolean;
  amount: number;
  transaction: string;
};
export type PortalTransactionDto = {
  owner?: string;
  asset?: string;
  amount: number;
  walletAmount: number;
  remainingInstallment?: InstallmentRo;
  walletInstallment?: InstallmentRo;
  transactionType: "PAYMENT_PORTAL" | "OFFLINE_PAYMENT" | "INCREASE_WALLET";
};
export type TransactionRo = {
  id: string;
  owner: string;
  amount: number;
  transactionType: "PAYMENT_PORTAL" | "OFFLINE_PAYMENT" | "INCREASE_WALLET";
  transId: string;
  increaseOrDecreaseInventory: boolean;
  token: string;
  isVerified: string;
  createdAt: string;
  description: string;
};
export type CreateInstallmentDto = {
  asset: string;
  dueDate: string;
  paymentDate: string;
  amount: number;
  paidStatus: boolean;
  transaction: string;
};
export type UpdateInstallmentDto = {
  id: string;
  asset: string;
  dueDate: string;
  paidStatus: boolean;
  amount: number;
  paymentDate: string;
  transaction: string;
};
export type IdDto = {
  id: string;
};
export type AssetRo = {
  id: string;
  haveAnyPaid: boolean;
  buyer: string;
  project: string;
  size: number;
  isConfirmedContract: boolean;
  paymentMode: "IN_CASH" | "IN_INSTALLMENT";
  createAt: string;
};
export type CreateAssetDto = {
  project: string;
  size: number;
  paymentMode: "IN_CASH" | "IN_INSTALLMENT";
};
export type AssetValuationRo = {
  project: string;
  totalSize: number;
  totalAmount: number;
};
export type OnlineBuyAssetDto = {
  project?: string;
  paymentMode: "IN_CASH" | "IN_INSTALLMENT";
  size: number;
};
export type PhoneNumberDto = {
  phoneNumber: string;
};
export type LoginRo = {
  token: string;
  user: UserRo;
};
export type LoginDto = {
  phoneNumber: string;
  OTPCode: string;
};
export type ProjectRo = {
  title: string;
  description: string;
  documentation: string[];
  termsOfContract: string;
  totalSize: number;
  price: number;
  prepaymentPercentage: number;
  numberOfInstallments: number;
  increaseInstallmentAmount: number;
  projectMode:
    | "LAND"
    | "RESIDENTIAL_COMPLEX"
    | "COMMERCIAL_BUILDING"
    | "TOWN_CONSTRUCTION";
  id: string;
};
export type CreateProjectDto = {
  title: string;
  description: string;
  price: number;
  documentation: string[];
  termsOfContract: string;
  totalSize: number;
  prepaymentPercentage: number;
  numberOfInstallments: number;
  increaseInstallmentAmount: number;
  projectMode:
    | "LAND"
    | "RESIDENTIAL_COMPLEX"
    | "COMMERCIAL_BUILDING"
    | "TOWN_CONSTRUCTION";
};
export type UpdateProjectDto = {
  id: string;
  title: string;
  description: string;
  documentation: string[];
  termsOfContract: string;
  totalSize: number;
  prepaymentPercentage: number;
  numberOfInstallments: number;
  increaseInstallmentAmount: number;
  projectMode:
    | "LAND"
    | "RESIDENTIAL_COMPLEX"
    | "COMMERCIAL_BUILDING"
    | "TOWN_CONSTRUCTION";
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: "ADMIN";
};
export type RoleDto = {
  name: string;
  permissions: "ADMIN"[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: "ADMIN"[];
};
export type MeetingRo = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};
export type CreateMeetingDto = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};
export const {
  useUserControllerFindUsersQuery,
  useUserControllerUpdateUserMutation,
  useUserControllerGetProfileQuery,
  useTransactionControllerChargeWalletMutation,
  useTransactionControllerCreatePortalTransactionMutation,
  useTransactionControllerGetWalletTransactionResponseQuery,
  useTransactionControllerGetPortalTransactionResponseQuery,
  useTransactionControllerGetMyTransactionsQuery,
  useInstallmentControllerCreateInstallmentMutation,
  useInstallmentControllerGetInstallmentsQuery,
  useInstallmentControllerPayInstallmentMutation,
  useInstallmentControllerUpdateInstallmentMutation,
  useInstallmentControllerDeleteInstallmentMutation,
  useInstallmentControllerGetNearestInstallmentQuery,
  useAssetControllerCreateAssetMutation,
  useAssetControllerGetAssetQuery,
  useAssetControllerGetMyAssetsQuery,
  useAssetControllerGetMyCartQuery,
  useAssetControllerMyAssetsValuationQuery,
  useAssetControllerUpdateAssetMutation,
  useAssetControllerActivateAssetMutation,
  useAssetControllerArchiveAssetMutation,
  useAssetControllerDeleteAssetMutation,
  useAssetControllerReturnAssetMutation,
  useAssetControllerOnlineBuyAssetMutation,
  useAuthControllerSendOtpMutation,
  useAuthControllerLoginMutation,
  useProjectControllerCreateProjectMutation,
  useProjectControllerGetProjectQuery,
  useProjectControllerFindProjectsQuery,
  useProjectControllerUpdateProjectMutation,
  useProjectControllerArchiveProjectMutation,
  useProjectControllerDeleteProjectMutation,
  useProjectControllerProjectProgressQuery,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useMeetingControllerCreateMeetingMutation,
  useMeetingControllerFindMeetingsQuery,
} = injectedRtkApi;

import { TextField } from "@mui/material";
import { forwardRef } from "react";
import { e2p } from "src/utils/convertNumber";

type CodeInputPropsType = {
  goNext: () => void;
  goBack: () => void;
  value?: number;
  autoFocus: boolean;
  setValue: (value?: number) => void;
  loading?: boolean;
};

export const CodeInput = forwardRef<HTMLInputElement, CodeInputPropsType>(
  ({ goNext, goBack, value, autoFocus, setValue, loading = false }, ref) => {
    return (
      <TextField
        autoFocus={autoFocus}
        inputRef={ref}
        type="text"
        disabled={loading}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          style: {
            textAlign: "center",
          },
        }}
        sx={{
          width: "60px",
          height: "60px",
          borderRadius: "5px",
        }}
        value={e2p(value === undefined ? "" : value)}
        onKeyUp={(e) => {
          const isNumber = /^[0-9]$/i.test(e.key);
          if (e.keyCode === 8) {
            if (value !== undefined) {
              setValue(undefined);
            } else {
              goBack();
            }
          } else if (isNumber) {
            setValue(+e.key);
            goNext();
          } else {
            e.preventDefault();
          }
        }}
      />
    );
  }
);

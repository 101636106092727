import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { toast } from "react-toastify";
import {
  InstallmentRo,
  useInstallmentControllerPayInstallmentMutation,
} from "src/app/services/generated-api";
import { formatDate } from "src/utils/dateFormatter";
import { formatPrice } from "src/utils/price";

export const InstallmentItem: FC<{ installment: InstallmentRo }> = ({
  installment,
}) => {
  const [pay, { isLoading }] = useInstallmentControllerPayInstallmentMutation();

  const payInstallment = () => {
    pay({ id: installment.id })
      .unwrap()
      .then(() => toast.success("قسط با موفقیت پرداخت شد!"))
      .catch((err) => toast.error(err.data));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: installment.paidStatus ? "#4B9461D9" : "#fff",
        borderRadius: "10px",
        p: "5px 10px",
        border: "1px solid #aaa",
      }}
    >
      <Typography
        sx={{
          fontSize: "19px",
          fontWeight: "500",
          color: installment.paidStatus ? "#fff" : "#4B9461D9",
        }}
      >
        {formatDate({ date: installment.dueDate })}
      </Typography>
      <Typography
        sx={{
          fontSize: "19px",
          fontWeight: "500",
          color: installment.paidStatus ? "#fff" : "#4B9461D9",
        }}
      >
        {formatPrice(installment.amount, true)} تومان
      </Typography>
      {installment.paidStatus ? (
        <Typography sx={{ color: "#fff", py: 1 }}>پرداخت شده</Typography>
      ) : (
        <LoadingButton
          variant="contained"
          onClick={() => payInstallment()}
          loading={isLoading}
        >
          پرداخت
        </LoadingButton>
      )}
    </Stack>
  );
};

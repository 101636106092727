import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";
import { CloseCircle } from "../atoms/icons/CloseCircle";
import { TermsText } from "../organisms/landing/TermsText";

export const AcceptTerms: FC<{
  open: boolean;
  handleClose: () => void;
  onAccept: () => void;
}> = ({ open, handleClose, onAccept }) => {
  const [inputValue, setInputValue] = useState("");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "1.8em", fontWeight: "600" }}>
            شرایط و قوانین
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseCircle sx={{ fontSize: "30px" }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: "50px" }}>
        <Box
          sx={{
            my: "10px",
            fontSize: "18px",
          }}
        >
          <TermsText />
        </Box>
        <Typography sx={{ my: "20px", fontSize: "18px" }}>
          لطفا عبارت تصویر را در کادر زیر بنویسید.
        </Typography>
        <Stack
          direction="row"
          sx={{
            border: "2px solid #41683B",
            borderRadius: "10px",
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Input
            disableUnderline={true}
            placeholder={"متن را وارد کنید."}
            sx={{
              color: "#1D1D3580",
              width: "100%",
            }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          ></Input>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            color="#41683B"
          />
          <Typography
            sx={{ color: "#1D1D35", width: "100%", fontSize: "19px" }}
          >
            قبول دارم
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: "50px" }}>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <Button
            variant="contained"
            onClick={() => {
              onAccept();
              handleClose();
            }}
            disabled={inputValue !== "قبول دارم"}
            sx={{
              width: "30%",
              fontSize: "20px",
              borderRadius: "10px",
            }}
          >
            تایید
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ReadMore } from "src/components/atoms/ReadMore";
import videoPoster from "src/assets/images/videoPoster.jpg";

export const VideoSection = () => {
  const videoText =
    "با توجه به شرایط اقتصادی کشور و افزایش قیمت‌های ملک و مستغلات, برآن شدیم تا سامانه‌ای را طراحی کنیم تا امکان سرمایه‌گذاری برای افرادی که توان مالی کافی برای خرید یک قطعه کامل از یک زمین را ندارند فراهم شود. این سامانه با بهره‌گیری از سال‌ها تجربه‌ی هلدینگ مهر رضوی در صنعت املاک و مستغلات و با استفاده از خلاقیت نیروی جوان ماهر در ایجاد نوآوری و فناوری و همچنین با پشتیبانی گروه حقوقی هلدینگ مهر رضوی توانسته‌است شرایطی منصفانه و البته سودآور را از این طریق برای تمام آحاد ملت شریف ایران و به خصوص قشر کم‌برخوردار فراهم آورد تا ضمن حفظ ارزش پول خود از سودی مناسب نیز بهره‌مند شوند. در این سامانه شما با کمترین میزان سرمایه‌گذاری میتوانید یک مقدار مشخص از یک زمین را خریداری کنید که به صورت کاملا قانونی قراردادی به نام شما تنظیم میشود که پس از خرید برای شما فرستاده خواهد شد.";

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded
    ? videoText
    : videoText.slice(0, 280) + "...";

  return (
    <Box
      sx={{
        padding: { md: "100px 50px" },
        borderRadius: { md: "12px" },
        boxShadow: { md: "rgba(0, 0, 0, 0.1) 0px 4px 20px" },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        textAlign="center"
        spacing={{ sm: 2, md: 10 }}
        sx={{ width: "100%" }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              borderRadius: "10px",
              position: "relative",
              "&:before": {
                content: '""',
                backgroundColor: "green",
                transform: "rotate(-7deg)",
                borderRadius: "10px",
                width: "100%",
                position: "absolute",
                height: "100%",
                zIndex: "-1",
              },
            }}
          >
            <video
              src="/video1.mp4"
              controls
              poster={videoPoster}
              style={{ borderRadius: "10px", width: "100%" }}
            ></video>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              display: { xs: "none", md: "block" },
              fontSize: "35px",
              fontWeight: "bold",
              textAlign: "left",
              mb: "40px",
            }}
          >
            فروش زمین های هشتگرد
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "27px" },
              fontWeight: { md: "500" },
              textAlign: "justify",
              my: "20px",
            }}
          >
            {displayedText}
          </Typography>
          {!isExpanded && (
            <Box
              sx={{ textAlign: "right", mb: "20px" }}
              onClick={handleExpandClick}
            >
              <ReadMore />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

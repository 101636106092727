import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const PaymentCode: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15 21H13V19H15V21ZM13 14H11V19H13V14ZM21 12H19V16H21V12ZM19 10H17V12H19V10ZM7 12H5V14H7V12ZM5 10H3V12H5V10ZM12 5H14V3H12V5ZM4.5 4.5V7.5H7.5V4.5H4.5ZM8 9H4C3.45 9 3 8.55 3 8V4C3 3.45 3.45 3 4 3H8C8.55 3 9 3.45 9 4V8C9 8.55 8.55 9 8 9ZM4.5 16.5V19.5H7.5V16.5H4.5ZM8 21H4C3.45 21 3 20.55 3 20V16C3 15.45 3.45 15 4 15H8C8.55 15 9 15.45 9 16V20C9 20.55 8.55 21 8 21ZM16.5 4.5V7.5H19.5V4.5H16.5ZM20 9H16C15.45 9 15 8.55 15 8V4C15 3.45 15.45 3 16 3H20C20.55 3 21 3.45 21 4V8C21 8.55 20.55 9 20 9ZM19 19V16H15V18H17V21H21V19H19ZM17 12H13V14H17V12ZM13 10H7V12H9V14H11V12H13V10ZM14 9V7H12V5H10V9H14ZM6.75 5.25H5.25V6.75H6.75V5.25ZM6.75 17.25H5.25V18.75H6.75V17.25ZM18.75 5.25H17.25V6.75H18.75V5.25Z"
        fill="black"
      />
    </SvgIcon>
  );
};

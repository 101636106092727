import { useEffect } from "react";
import { theme } from "./config/theme";
import { CacheProvider } from "@emotion/react";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "src/routes/Router";
import { ToastContainer } from "react-toastify";
import createEmotionCache from "./createEmotionCache";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "src/assets/general.css";
import { logoutAction } from "./app/slices/authSlice";
import { isExpiredJwt } from "./utils/parseJwtUtils";
import { useAppDispatch, useAppSelector } from "./app/hooks";

document.dir = "rtl";

function App() {
  useEffect(() => {
    document.dir = "rtl";
  }, []);

  const dispatch = useAppDispatch();
  const token = useAppSelector((store) => store.auth.token);

  useEffect(() => {
    if (!token) return;
    const expired = isExpiredJwt(token);
    if (expired) {
      dispatch(logoutAction());
    }
  }, [dispatch, token]);

  return (
    <CacheProvider value={createEmotionCache()}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={document.dir === "rtl"}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;

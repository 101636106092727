import { FC, useState } from "react";
import { ContentPropsType } from "../constants";
import {
  Box,
  Button,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IOSSwitch } from "src/components/atoms/Switch";
import { FactorItem } from "./components/FactorItem";
import { canBuyAsset } from "src/helpers/user";
import { Add } from "@mui/icons-material";
import {
  useAssetControllerOnlineBuyAssetMutation,
  useUserControllerGetProfileQuery,
} from "src/app/services/generated-api";
import { LoadingButton } from "@mui/lab";
import { useProject } from "src/components/hooks/useProject";
import { formatPrice } from "src/utils/price";
import { formatDate } from "src/utils/dateFormatter";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { e2p } from "src/utils/convertNumber";

export const FactorContent: FC<ContentPropsType> = ({
  openAuthDialog,
  openPaymentDialog,
  totalAmount,
  isCash,
  handleCloseDialog,
}) => {
  const [useWallet, setUseWallet] = useState(true);

  const { data: user } = useUserControllerGetProfileQuery();
  const [onlineBuyAsset, { isLoading: isAssetBuying }] =
    useAssetControllerOnlineBuyAssetMutation();

  const project = useProject();

  const navigate = useNavigate();

  const buyAsset = () => {
    if (!project) return;

    onlineBuyAsset({
      onlineBuyAssetDto: {
        project: project.id,
        paymentMode: isCash ? "IN_CASH" : "IN_INSTALLMENT",
        size: totalAmount / project.price,
      },
    })
      .unwrap()
      .then((asset) => {
        toast.success("خرید شما با موفقیت انجام شد!");
        navigate(`/assets/${asset.id}`);
      });
  };

  const checkEnoughMoney = () => {
    if (!user || !project) return false;

    return user.walletBalance > 0.1 * totalAmount;
  };

  return (
    <>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{ minHeight: "50vh" }}
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6">جزئیات خرید</Typography>
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              sx={{ borderRadius: 2, px: 3, py: 2 }}
            >
              <Typography>خرید زمین</Typography>
              <Typography>
                {e2p(totalAmount / (project?.price || 5000000))} متر
              </Typography>
              <Typography>{e2p(totalAmount / 1000000)} میلیون تومان</Typography>
            </Stack>
          </Stack>

          <Stack>
            <Divider sx={{ width: "100%", mb: 1 }} />
            <Typography variant="h6">جزئیات پرداخت</Typography>
            {isCash ? (
              <>
                <FactorItem
                  items={[
                    "پیش‌پرداخت",
                    formatDate({ date: Date.now() + 3 * 24 * 60 * 60 * 1000 }),
                    e2p((totalAmount * 0.45) / 1000000) + " میلیون تومان",
                  ]}
                />
                <FactorItem
                  items={[
                    "قسط‌‌‌‌‌ ‌ ‌ ‌ ‌ ‌ ‌",
                    formatDate({
                      date: Date.now() + 48 * 24 * 60 * 60 * 1000,
                    }),
                    e2p((totalAmount * 0.5) / 1000000) + " میلیون تومان",
                  ]}
                />
              </>
            ) : (
              <>
                <FactorItem
                  items={[
                    "پیش‌پرداخت",
                    formatDate({ date: Date.now() + 3 * 24 * 60 * 60 * 1000 }),
                    e2p((totalAmount * 0.45) / 1000000) + " میلیون تومان",
                  ]}
                />
                {[...Array(6)].map((_, index) => (
                  <>
                    <FactorItem
                      items={[
                        "قسط‌‌‌‌‌ ‌ ‌ ‌ ‌ ‌ ‌",
                        formatDate({
                          date:
                            Date.now() +
                            (index * 30 + 33) * 24 * 60 * 60 * 1000,
                        }),
                        e2p((totalAmount * 0.115) / 1000000) + " میلیون تومان",
                      ]}
                    />
                  </>
                ))}
              </>
            )}

            {useWallet && (
              <FactorItem
                items={[
                  "کیف پول",
                  "-",
                  e2p(
                    formatPrice(
                      Math.round((user?.walletBalance || 0) / 1000000)
                    )
                  ) + " میلیون تومان",
                ]}
                color="#ACDA9080"
              />
            )}
          </Stack>
          <Box>
            <Divider sx={{ width: "100%", mb: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack direction="row" alignItems="flex-end" spacing={2}>
                    <Typography fontWeight={500}>
                      استفاده از اعتبار کیف پول
                    </Typography>
                    <Typography variant="caption">
                      موجودی{" "}
                      {e2p(
                        formatPrice(
                          Math.round((user?.walletBalance || 0) / 1000000)
                        )
                      )}{" "}
                      میلیون تومان
                    </Typography>
                  </Stack>
                  <IOSSwitch
                    checked={useWallet}
                    onClick={() => setUseWallet(!useWallet)}
                    disabled
                  />
                </Stack>
              </Grid>
              {!user ? (
                <>
                  <Grid item xs={8}>
                    برای ادامه خرید ابتدا باید وارد شوید
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      onClick={() => openAuthDialog()}
                      variant="contained"
                      fullWidth
                    >
                      ورود
                    </Button>
                  </Grid>
                </>
              ) : !canBuyAsset(user) ? (
                <>
                  <Grid item xs={8}>
                    برای ادامه خرید باید اطلاعات کاربری خود را تکمیل کنید
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      onClick={() => openAuthDialog()}
                      variant="contained"
                      fullWidth
                    >
                      تکمیل اطلاعات
                    </Button>
                  </Grid>
                </>
              ) : checkEnoughMoney() ? (
                <>
                  <Grid item xs={8}>
                    درخواست ثبت دارایی
                  </Grid>
                  <Grid item xs={4}>
                    <LoadingButton
                      onClick={() => buyAsset()}
                      variant="contained"
                      fullWidth
                      loading={isAssetBuying}
                    >
                      خرید و رزرو دارایی
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={8}>
                    عدم موجودی کافی
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      onClick={() => openPaymentDialog()}
                      variant="contained"
                      fullWidth
                      startIcon={<Add />}
                    >
                      شارژ کیف پول
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Stack>
      </DialogContent>
    </>
  );
};

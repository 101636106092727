import { FC } from "react";
import { AuthCode } from "./dialogPages/AuthCode";
import { ProfileContent } from "./dialogPages/ProfileContent";
import { SignUpContent } from "./dialogPages/SignUpContent";

type authPageType = { title: string; component: FC<ContentPropsType> };

export const authPages: authPageType[] = [
  { title: "ورود", component: SignUpContent },
  { title: "کد", component: AuthCode },
  { title: "پروفایل", component: ProfileContent },
];

export type ContentPropsType = {
  changePage: (page: number) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  handleCloseDialog: () => void;
};

export const formatPrice = (num: string | number, roundNumber = false) => {
  if (roundNumber) {
    if (typeof num === "number") {
      num = Math.round(num);
    } else {
      num = num.split(".")[0];
    }
  }
  if (num === 0) return "0";
  let res = "";
  const splitedNum = ("" + num).replace(/^0+/, "").split(".");
  for (let i = 0; i < splitedNum[0].length; i++) {
    if ((splitedNum[0].length - i) % 3 === 0 && i !== 0) {
      res += ",";
    }
    res += splitedNum[0][i];
  }
  return res + (splitedNum.length > 1 ? "." + splitedNum[1] : "");
};

import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Landing } from "../pages/Landing";
import { OldLanding } from "../pages/OldLanding";
import { UserPanel } from "src/pages/UserPanel";
import { Asset } from "src/pages/Asset";
import { Gallery } from "src/components/molecules/Slider/GallerySlideShow";

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/wallet/error" element={<Landing error />} />
        <Route path="/wallet/error/:id" element={<Landing success />} />
        <Route path="/wallet/success" element={<Landing success />} />
        <Route path="/wallet/success/:id" element={<Landing success />} />
        <Route path="/old" element={<OldLanding />} />
        <Route path="/userPanel" element={<UserPanel />} />
        <Route path="/assets/:assetId" element={<Asset />} />
        <Route path="/test" element={<Gallery />} />
      </Routes>
    </BrowserRouter>
  );
};

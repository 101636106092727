import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState, ChangeEvent } from "react";
import { useProject } from "src/components/hooks/useProject";
import { e2p, p2e } from "src/utils/convertNumber";
import { formatPrice } from "src/utils/price";
import { ContentPropsType } from "../constants";

export const SelectPaymentContent: FC<ContentPropsType> = ({
  handleCloseDialog,
  setPage,
  totalAmount,
  setTotalAmount,
  isCash,
  setIsCash,
}) => {
  const [unit, setUnit] = useState<"t" | "m">("t");

  const project = useProject();

  const priceOfMeter = project?.price || 5000000;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount =
      +p2e(e.target.value, true) * (unit === "t" ? 1000000 : priceOfMeter);
    setTotalAmount(amount);
  };

  return (
    <>
      <DialogContent>
        <Typography sx={{ fontSize: "19px", fontWeight: "500", mb: 1 }}>
          مبلغ سرمایه گذاری را وارد کنید.
        </Typography>
        <Stack spacing={2}>
          <TextField
            className="ltr-input"
            placeholder={unit === "t" ? "1,000" : "10"}
            value={e2p(
              formatPrice(
                unit === "t"
                  ? totalAmount / 1000000
                  : totalAmount / priceOfMeter
              )
            )}
            sx={{
              border: "1px solid #41683B",
              borderRadius: "10px",
              justifyContent: "right",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              "& .MuiInputBase-input": {
                "&.Mui-focused fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "& fieldset": {
                  border: "none",
                },
                textAlign: "right",
                pr: 4,
              },
            }}
            fullWidth
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    value={unit}
                    onChange={(e) => {
                      setUnit(e.target.value as "t" | "m");
                    }}
                    variant="standard"
                    disableUnderline={true}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      textAlign: "right",
                      minWidth: 100,
                      "& .MuiOutlinedInput-notchedOutline": { border: 0 },
                      "& .MuiSelect-icon": { left: 0 },
                      "& .MuiInputBase-input": {
                        pl: 4,
                        textAlign: "center",
                      },
                      "& .MuiInputBase-input:focus": {
                        backgroundColor: "unset",
                      },
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem value="t">میلیون تومان</MenuItem>
                    <MenuItem value="m">متر</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Stack spacing={2} width="100%">
            <Box
              sx={{
                border: `1px solid ${isCash ? "#2e7d32" : "#333"}`,
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              onClick={() => setIsCash(true)}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                width="100%"
              >
                <Radio checked={isCash} color="success" />
                <Typography sx={{ fontWeight: "600", fontSize: "17px" }}>
                  پرداخت نقدی
                </Typography>
              </Stack>
              <Stack spacing={1} px="10px">
                <Typography>
                  پرداخت {e2p(formatPrice(totalAmount, true))} تومان
                </Typography>
                <Typography>
                  میزان سهم دریافتی: {e2p(totalAmount / priceOfMeter / 200)} سهم
                  از هر قطعه
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                border: `1px solid ${isCash ? "#333" : "#2e7d32"}`,
                borderRadius: "10px",
                padding: "20px",
                background: totalAmount >= 1000000000 ? "" : "#efefef",
                boxShadow:
                  totalAmount >= 1000000000
                    ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                    : "unset",
              }}
              onClick={() => totalAmount >= 1000000000 && setIsCash(false)}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                width="100%"
              >
                <Radio
                  checked={!isCash}
                  color="success"
                  disabled={totalAmount < 1000000000}
                />
                <Typography sx={{ fontWeight: "600", fontSize: "17px" }}>
                  پرداخت قسطی
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  "حداقل ۱ میلیارد تومان"
                </Typography>
              </Stack>
              <Stack spacing={1} px="10px">
                <Typography>اقساط ۶ ماهه</Typography>
                <Typography>
                  پیش پرداخت:{" "}
                  {e2p(formatPrice(totalAmount * 0.35 * 1.15, true))} تومان
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>
                    اقساط: {e2p(formatPrice(totalAmount * 0.1 * 1.15, true))}{" "}
                    تومان
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>*شش قسط*</Typography>
                </Stack>
                <Typography>
                  میزان سهم دریافتی: {e2p(totalAmount / priceOfMeter / 200)} سهم
                  از هر قطعه
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>بدون نیاز سفته و چک</Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    , در اقساط بلند مدت و بدون بهره
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-around"
          width="100%"
          pb={3}
        >
          <Button
            sx={{
              color: "black",
              border: "1px solid #41683B",
              borderRadius: "12px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              p: "4px 40px",
              fontSize: "18px",
            }}
            onClick={handleCloseDialog}
          >
            انصراف
          </Button>
          <Button
            variant="contained"
            sx={{ p: "4px 40px", fontSize: "18px", borderRadius: "12px" }}
            onClick={() => setPage(1)}
          >
            ادامه
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

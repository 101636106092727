import { Box } from "@mui/material";
import { PieChart, Pie, Cell } from "recharts";
import { ChartLegend } from "./ChartLegend";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  percent: any;
  index: any;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x + (index === 0 ? 8 : 12)}
      y={y + (index === 0 ? 0 : 7)}
      fill="white"
      dominantBaseline="central"
      fontWeight={900}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const data = [
  { name: "remained", value: 250 },
  { name: "sold", value: 750 },
];

export const Chart = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={140}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
            startAngle={-270}
          >
            <Cell
              fill="#FBBC04"
              name="حجم باقی مانده"
              style={{ transform: "translate(-8px, -8px)" }}
            ></Cell>
            <Cell fill="#4B9461" name="حجم خریداری شده"></Cell>
          </Pie>
        </PieChart>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" }, width: "100%" }}>
        <ChartLegend />
      </Box>
    </Box>
  );
};

import { Box, Grid, Typography } from "@mui/material";
import { Chart } from "src/components/atoms/Chart";
import { ChartLegend } from "src/components/atoms/ChartLegend";

export const ChartSection = () => {
  return (
    <Box
      sx={{
        padding: { md: "80px 50px" },
        borderRadius: { md: "12px" },
        boxShadow: { md: "rgba(0, 0, 0, 0.1) 0px 4px 20px" },
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Typography sx={{ fontSize: { xs: "1.5em", md: "35px" }, py: { xs: "10px", md: "0"}, fontWeight: { md: "bold" }, mb: { md: "40px" } }}>
            حجم باقی مانده از پروژه
          </Typography>
          <Typography fontSize={{ xs: "18px", md: "23px" }} fontWeight={{ md: "500" }} mb={{ md: "40px" }}>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
            استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است
          </Typography>
          <Box sx={{ display: { xs: "none", md: "block" }, textAlign: "left" }}>
            <ChartLegend />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Chart />
        </Grid>
      </Grid>
    </Box>
  );
};

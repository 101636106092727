import { FC } from "react";
import { SelectPaymentContent } from "./dialogPages/SelectPaymentContent";
import { FactorContent } from "./dialogPages/FactorContent";

type buyPageType = { component: FC<ContentPropsType> };

export const buyPages: buyPageType[] = [
  { component: SelectPaymentContent },
  { component: FactorContent },
];

export type ContentPropsType = {
  setPage: (pageIndex: number) => void;
  handleCloseDialog: () => void;
  openPaymentDialog: () => void;
  openAuthDialog: () => void;
  totalAmount: number;
  setTotalAmount: (totalAmount: number) => void;
  isCash: boolean;
  setIsCash: (isCash: boolean) => void;
};

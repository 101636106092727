import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const InstaIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9995 16.2941V10.563V7.70541C18.9995 6.21314 17.7859 5 16.2936 5H7.70541C6.21314 5 5 6.21314 5 7.70541V10.563V16.2941C5 17.7869 6.21314 19 7.70541 19H16.2941C17.7859 19 18.9995 17.7869 18.9995 16.2941ZM14.4642 11.9993C14.4642 13.3582 13.3572 14.4632 11.9993 14.4632C10.6409 14.4632 9.53479 13.3577 9.53628 11.9988C9.53678 11.4623 9.71079 10.9671 10.0023 10.5625C10.451 9.94182 11.1783 9.53529 12.0007 9.53529C12.8227 9.53529 13.5505 9.94231 13.9982 10.563C14.2887 10.9676 14.4642 11.4628 14.4642 11.9993ZM17.3798 8.98499V6.91961V6.61224L17.071 6.61323L15.0066 6.61967L15.0145 8.99292L17.3798 8.98499ZM11.9998 15.8281C14.1112 15.8281 15.8286 14.1097 15.8286 11.9993C15.8286 11.4916 15.7259 11.0062 15.544 10.563H17.6347V16.2941C17.6347 17.0348 17.0323 17.6347 16.2931 17.6347H7.70491C6.96473 17.6347 6.36336 17.0348 6.36336 16.2941V10.563H8.45303C8.27257 11.0062 8.17143 11.4916 8.17143 11.9993C8.17143 14.1097 9.88827 15.8281 11.9998 15.8281Z"
        fill="white"
      />
      <mask
        id="mask0_23_90"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9995 16.2941V10.563V7.70541C18.9995 6.21314 17.7859 5 16.2936 5H7.70541C6.21314 5 5 6.21314 5 7.70541V10.563V16.2941C5 17.7869 6.21314 19 7.70541 19H16.2941C17.7859 19 18.9995 17.7869 18.9995 16.2941ZM14.4642 11.9993C14.4642 13.3582 13.3572 14.4632 11.9993 14.4632C10.6409 14.4632 9.53479 13.3577 9.53628 11.9988C9.53678 11.4623 9.71079 10.9671 10.0023 10.5625C10.451 9.94182 11.1783 9.53529 12.0007 9.53529C12.8227 9.53529 13.5505 9.94231 13.9982 10.563C14.2887 10.9676 14.4642 11.4628 14.4642 11.9993ZM17.3798 8.98499V6.91961V6.61224L17.071 6.61323L15.0066 6.61967L15.0145 8.99292L17.3798 8.98499ZM11.9998 15.8281C14.1112 15.8281 15.8286 14.1097 15.8286 11.9993C15.8286 11.4916 15.7259 11.0062 15.544 10.563H17.6347V16.2941C17.6347 17.0348 17.0323 17.6347 16.2931 17.6347H7.70491C6.96473 17.6347 6.36336 17.0348 6.36336 16.2941V10.563H8.45303C8.27257 11.0062 8.17143 11.4916 8.17143 11.9993C8.17143 14.1097 9.88827 15.8281 11.9998 15.8281Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_23_90)">
        <rect width="24" height="24" fill="white" />
      </g>
    </SvgIcon>
  );
};

import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";
import { toast } from "react-toastify";
import { useTransactionControllerChargeWalletMutation } from "src/app/services/generated-api";
import { Payment2 } from "../atoms/icons/paymentDialog/Payment2";
import { PaymentCode } from "../atoms/icons/paymentDialog/PaymentCode";
import * as yup from "yup";
import { e2p, p2e } from "src/utils/convertNumber";

export const ChargeWallet: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const [chargeWallet, { isLoading }] =
    useTransactionControllerChargeWalletMutation();

  const formik = useFormik({
    initialValues: {
      walletTransaction: 0,
    },
    validationSchema: yup.object({
      walletTransaction: yup.number(),
    }),
    onSubmit: (values) => {
      chargeWallet({
        walletTransactionDto: {
          amount: values.walletTransaction,
          transactionType: "INCREASE_WALLET",
          description: "افزایش موجودی حساب",
        },
      })
        .unwrap()
        .then((response) => {
          window.location.href = response;
        })
        .catch((error) => {
          toast.error("مشکلی پیش آمده. لطفا دوباره تلاش کنید.");
        });
    },
  });

  return (
    <>
      <Stack spacing={3}>
        <Stack direction="row" spacing={2} alignItems="center">
          <PaymentCode sx={{ fontSize: "30px" }} />
          <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
            شناسه پرداخت
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            border: { md: "1px solid #41683B", xs: "1px solid #C8CACE" },
            borderRadius: "20px",
            width: "100%",
          }}
        >
          <TextField
            placeholder="14687592146235"
            sx={{
              width: "60%",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          ></TextField>
          <Button
            variant="contained"
            sx={{ fontSize: "18px", width: "40%", borderRadius: "20px" }}
          >
            تایید
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Payment2 sx={{ fontSize: "30px" }} />
          <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
            درگاه پرداخت
          </Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            direction="row"
            sx={{
              border: { md: "1px solid #41683B", xs: "1px solid #C8CACE" },
              borderRadius: "20px",
              width: "100%",
            }}
          >
            <TextField
              placeholder="1,000,000,000 ت"
              sx={{
                width: "60%",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              {...formik.getFieldProps("walletTransaction")}
              value={e2p(formik.values.walletTransaction || "")}
              onChange={(e) => {
                formik.setFieldValue(
                  "walletTransaction",
                  p2e(e.target.value, true)
                );
              }}
            ></TextField>
            <LoadingButton
              variant="contained"
              sx={{ fontSize: "18px", width: "40%", borderRadius: "20px" }}
              type="submit"
              loading={isLoading}
            >
              تایید
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

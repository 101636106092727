import jMoment from "jalali-moment";
import moment from "moment";
import translateNumber from "./translateNumber";

const defaultType = {
  fa: "jalali",
  en: "gregorian",
};

type dateFormatterBaseType = {
  date?: number | string | Date;
  lang?: "fa" | "en";
  pastFormat?: boolean;
  utc?: boolean;
  fromNow?: boolean;
};

type dateFormatterPastType = dateFormatterBaseType & { pastFormat: true };

type dateFormatterGeneralType = dateFormatterBaseType & {
  pastFormat?: false;
  format?: string;
};

type dateFormatterType = dateFormatterPastType | dateFormatterGeneralType;

export const formatDate = (props: dateFormatterType) => {
  const {
    date = new Date(),
    lang = "fa",
    pastFormat,
    utc = false,
    fromNow = false,
  } = props;
  let result;
  let format = pastFormat ? getPastFormat(date) : props?.format || "YYYY/MM/DD";

  switch (defaultType[lang]) {
    case "jalali":
      result = (utc ? jMoment.utc(date) : jMoment(date)).locale("fa");
      break;
    case "gregorian":
    default:
      result = utc ? moment.utc(date) : moment(date);
      break;
  }

  if (fromNow) {
    result = result.fromNow();
  } else {
    result = result.format(format);
  }

  return translateNumber({
    lang,
    num: result,
  });
};

export const getPastFormat = (date: Date | string | number) => {
  const todaysDate = new Date();
  const inputDate = new Date(date);
  if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
    return "HH:mm:ss";
  }
  return "YYYY/MM/DD";
};

export const isDateOrDateString = (item: any): boolean => {
  if (item instanceof Date) {
    return true;
  }

  if (typeof item === "string" && item.length > 6) {
    const date = Date.parse(item);
    return !isNaN(date);
  }

  return false;
};

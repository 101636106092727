import { Typography } from "@mui/material";
import { QAText } from "./Q&A";

export default function QuestionAnswer() {
  return (
    <div>
      <Typography sx={{ fontSize: "1.5em", py: "10px", fontWeight: "500" }}>
        سوالات متداول
      </Typography>
      <QAText />
    </div>
  );
}

import {
  Box,
  Button,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatPrice } from "src/utils/price";
import { TransactionReceipt } from "../atoms/icons/TransactionReceipt";
import { TransactionCard } from "./TransactionCard";
import { useTransactionControllerGetMyTransactionsQuery } from "src/app/services/generated-api";
import { formatDate } from "src/utils/dateFormatter";
import { cyrb53 } from "src/utils/hash";
import { e2p } from "src/utils/convertNumber";

export type Transactions = {
  title: string;
  price: string;
  date: Date;
  hour: number;
  minute: number;
  trackingCode: number;
};

export const TransactionInfo = () => {
  const { data: transactions = [] } =
    useTransactionControllerGetMyTransactionsQuery();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <>
      <Stack direction="row" spacing={1}>
        <TransactionReceipt />
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          تراکنش ها
        </Typography>
      </Stack>
      <Stack
        spacing={1}
        sx={{
          border: { md: "2px solid #41683B" },
          borderRadius: { md: "20px" },
          p: { md: "20px" },
          boxShadow: { md: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" },
        }}
      >
        {isMobile ? (
          <>
            {transactions.map((transaction) => {
              return (
                <TransactionCard
                  key={transaction.id}
                  transaction={transaction}
                />
              );
            })}
          </>
        ) : (
          <Box sx={{ mx: "50px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                borderBottom: "2px solid #41683B",
                p: "20px 0",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  width: "16%",
                  textAlign: "center",
                }}
              >
                تراکنش
              </Box>
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  width: "16%",
                  textAlign: "center",
                }}
              >
                شارژ کیف‌پول
              </Box>
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  width: "16%",
                  textAlign: "center",
                }}
              >
                تاریخ خرید
              </Box>
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  width: "16%",
                  textAlign: "center",
                }}
              >
                زمان پرداخت
              </Box>
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  width: "16%",
                  textAlign: "center",
                }}
              >
                کد پیگیری
              </Box>
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  width: "16%",
                  textAlign: "center",
                }}
              >
                رسید
              </Box>
            </Stack>
            {transactions.map((transaction) => {
              const date = new Date(transaction.createdAt);
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderBottom: "2px solid #41683B",
                    p: "20px",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      width: "16%",
                      textAlign: "center",
                    }}
                  >
                    {transaction.description}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      width: "16%",
                      textAlign: "center",
                    }}
                  >
                    {e2p(formatPrice(transaction.amount, true))} تومان
                  </Box>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      width: "16%",
                      textAlign: "center",
                    }}
                  >
                    {formatDate({ date, format: "YYYY/MM/DD" })}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      width: "16%",
                      textAlign: "center",
                    }}
                  >
                    {formatDate({ date, format: "HH:mm" })}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      width: "16%",
                      textAlign: "center",
                    }}
                  >
                    {e2p(cyrb53(transaction.transId))}
                  </Box>
                  <Box sx={{ width: "16%", textAlign: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "20px",
                        borderRadius: "12px",
                        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      دریافت رسید
                    </Button>
                  </Box>
                </Stack>
              );
            })}
          </Box>
        )}
      </Stack>
    </>
  );
};

import { Box } from "@mui/material";
import { useState } from "react";
import { GalleryItem } from "./GalleryItem";

const images = [
  { index: "1", url: "/images/001.jpg" },
  { index: "2", url: "/images/002.jpg" },
  { index: "3", url: "/images/003.jpg" },
  { index: "4", url: "/images/004.jpg" },
  { index: "5", url: "/images/005.jpg" },
  { index: "6", url: "/images/006.jpg" },
  { index: "7", url: "/images/007.jpg" },
  { index: "8", url: "/images/008.jpg" },
  { index: "9", url: "/images/009.jpg" },
];

export const Gallery = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const listLength = images.length;
  const goNext = () => {
    if (currentSlide === 0) {
      setCurrentSlide(listLength - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };
  const goPrev = () => {
    if (currentSlide === listLength - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        margin: "0 auto",
        width: "100%",
        paddingBottom: "50%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          width: "70%",
          height: "100%",
          transform: "translateX(-50%)",
        }}
      >
        {images.map((image, index) => (
          <GalleryItem
            index={index}
            currentSlide={currentSlide}
            listLength={listLength}
            src={image.url}
          />
        ))}
      </Box>
      <Box
        onClick={goNext}
        sx={{
          position: "absolute",
          left: "5%",
          top: "30%",
          height: "40%",
          cursor: "pointer",
        }}
      >
        <img
          src="images/rightArrow.svg"
          alt="next"
          style={{ height: "100%" }}
        />
      </Box>
      <Box
        onClick={goPrev}
        sx={{
          position: "absolute",
          right: "5%",
          top: "30%",
          height: "40%",
          cursor: "pointer",
        }}
      >
        <img src="images/leftArrow.svg" alt="back" style={{ height: "100%" }} />
      </Box>
    </Box>
  );
};

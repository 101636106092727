import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { CloseCircle } from "src/components/atoms/icons/CloseCircle";
import { authPages } from "./constants";
import { useUserControllerGetProfileQuery } from "src/app/services/generated-api";

export const AuthDialog: FC<{
  open: boolean;
  initPageNumber?: number;
  handleClose: () => void;
}> = ({ open, initPageNumber, handleClose }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [phoneNumber, setPhoneNumber] = useState("");

  const [pageNumber, setPageNumber] = useState(initPageNumber || 0);

  const { data: user } = useUserControllerGetProfileQuery();

  useEffect(() => {
    if (initPageNumber === undefined) return;
    setPageNumber(initPageNumber);
  }, [initPageNumber]);

  useEffect(() => {
    if (user) {
      setPageNumber(2);
    }
  }, [user]);

  const ContentComponent = authPages[pageNumber].component;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      fullScreen={isMobile}
      scroll="body"
    >
      <DialogTitle sx={{ textAlign: "right" }}>
        <IconButton onClick={handleClose}>
          <CloseCircle sx={{ fontSize: "30px" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack
          sx={{
            backgroundColor: "white",
            minHeight: "70vh",
            px: { xs: 1, sm: 10 },
            pb: 1,
          }}
          spacing={8}
        >
          {pageNumber !== 2 && (
            <img src="/images/logo.svg" alt="logo" style={{ width: "100%" }} />
          )}
          <ContentComponent
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            changePage={(page: number) => setPageNumber(page)}
            handleCloseDialog={handleClose}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

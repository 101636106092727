import { Button, Stack, Typography } from "@mui/material"
import { CircleLeft } from "./icons/CircleLeft"
import { LeftArrow } from "./icons/LeftArrow";

export const ReadMore = () => {
  return (
    <Button sx={{ color: "black" }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={{ fontSize: { xs: "20px", md: "26px" }, fontWeight: { md: "500" } }}>
          بیشتر
        </Typography>
        <CircleLeft
          sx={{ fontSize: "30px", display: { xs: "block", md: "none" } }}
        />
        <LeftArrow sx={{ display: { xs: "none", md: "block" } }} />
      </Stack>
    </Button>
  );
}
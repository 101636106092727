import {
  Box,
  Button,
  Container,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";
import { PurchaseButton } from "src/components/atoms/PurchaseButton";
import { CardSlider } from "src/components/molecules/Slider/CardSlider";
import { Counsel } from "src/components/molecules/Counsel";
import { LandingCard } from "src/components/molecules/LandingCard";
import { ChartSection } from "src/components/organisms/landing/ChartSection";
import { Contracts } from "src/components/organisms/landing/Contracts";
import { LandingCards } from "src/components/organisms/landing/LandingCards";
import { LandingMap } from "src/components/organisms/landing/Map";
import { ErrorDialog } from "src/components/organisms/landing/PaymentResponse/ErrorDialog";
import { SuccessDialog } from "src/components/organisms/landing/PaymentResponse/SuccessDialog";
import QuestionAnswer from "src/components/organisms/landing/QuestionAnswer";
import { DashboardTemplate } from "src/components/templates/Dashboard";
import { Gallery } from "src/components/molecules/Slider/GallerySlideShow";

export const Landing: FC<{ error?: boolean; success?: boolean }> = ({
  error = false,
  success = false,
}) => {
  const [openErrorPaymentDialog, setOpenErrorPaymentDialog] = useState(error);
  const [openSuccessPaymentDialog, setOpenSuccessPaymentDialog] =
    useState(success);

  const [isExpanded, setIsExpanded] = useState(false);
  const IntroText =
    "پروژه شهر سازی حکمت رضوی در استان البرز و در مجاورت استان قزوین، در محدوده ی غرب مناطق کوهسار و کردان، و هم چنین شمال اتوبان کرج قزوین واقع شده است. این منطقه به تازگی و طبق مصوبه ی ....... شهرداری وارد بافت شهری شده است که با توجه به شرایط ییلاقی و خوش آب و هوای منطقه و طبق طرح تفصیلی، این منطقه دارای بافت کم تراکم و یلایی بوده و به قطعات 200 متری تفکیک خواهد شد. همچنین انواع امکانات رفاهی ممتاز نظیر دسترسی اختصاصی از اتوبان اصلی، فضای سبز گسترده، مکان های اداری تجاری آموزشی و فرهنگی و ... با توجه به کاربری شهری برای این منطقه در نظر گرفته شده است که با ترکیب معماری نوین و موثر، فضای مناسبی را برای ساکنین فراهم خواهد کرد.";

  const displayedText = isExpanded
    ? IntroText
    : IntroText.slice(0, 280) + "...";

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      <DashboardTemplate>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            pb: { xs: "25%", sm: "20%", md: "15%" },
          }}
        >
          <img
            style={{ width: "100%" }}
            src="/images/landing_header.png"
            alt="header"
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "7%",
              right: "10%",
              width: "100%",
              height: "35%",
            }}
          >
            <Box
              sx={{
                bgcolor: (theme) => theme.palette.primary.main,
                height: "100%",
                width: "120%",
                transform: "rotate(-3deg)",
              }}
            ></Box>
            <Box
              sx={{
                bgcolor: "white",
                height: "150%",
                width: "120%",
                transform: "rotate(-3deg)",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: { xs: "15%", sm: "17%", md: "20%" },
              left: { xs: "5%", sm: "10%" },
              color: "white",
              zIndex: 2,
            }}
          >
            <Typography
              variant="h2"
              fontWeight={700}
              fontSize={{ xs: 23, sm: 35, md: 55, lg: 60 }}
            >
              فرصتی به وسعت یک شهر...
            </Typography>
            <Typography
              variant="h6"
              mt={1}
              fontSize={{ xs: 12, sm: 22, md: 27, lg: 30 }}
            >
              احداث شهری جدید در تلاقی سه استان تهران، البرز و قزوین.
            </Typography>
          </Box>
        </Box>
        <Container sx={{ mt: { xs: 2, sm: 0 } }}>
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            fontSize={{ xs: 16, sm: 32 }}
          >
            حکمت رضوی، چشم اندازی مطمئن از آینده
          </Typography>
          <Typography
            fontSize={{ xs: 14, sm: 22 }}
            fontWeight={400}
            textAlign="justify"
          >
            {displayedText}
            {!isExpanded && (
              <Button
                sx={{ fontWeight: 700, fontSize: 23 }}
                onClick={() => setIsExpanded(true)}
              >
                بیشتر
              </Button>
            )}
          </Typography>
        </Container>
        <Box sx={{ mt: 2 }}>
          <LandingMap />
        </Box>
        {isMobile ? <CardSlider /> : <LandingCards />}
        <Container sx={{ my: 5 }}>
          <ChartSection />
        </Container>
        {/* <Gallery /> */}
        <Container sx={{ my: 5 }}>
          <QuestionAnswer />
        </Container>
        <Container sx={{ my: 5 }}>
          <Stack
            sx={{
              "& .image-container": { maxWidth: { sm: "30vw", md: "20vw" } },
            }}
            spacing={4}
          >
            <LandingCard
              direction={"row"}
              src="/images/9.png"
              title="معتمد بودن ما را محک بزنید."
              items={[
                "1. تمام زمین‌های مورد معامله دارای اسناد تک برگ شخصی به همراه استعلامات ثبتی در دفتر مرکزی موجود است.",
                "2. وجود اسناد معاملاتی معتبر با قابلیت استعلام.",
                "3. امکان استعلام طرح تفصیلی ابلاغیه شهرداری ..... در تاریخ ..... .",
                "4. عقد قرارداد معتبر حقوقی با قابلیت پیگیری.",
                "5. ارائه اعتبار نامه مالکیت به منظور احراز مالکیت تا پیش از ارائه سند تک برگ.",
                "6. طرح توجیهی جامع جهت تشریح ابعاد مختلف پروژه و برنامه های شهرسازی.",
                "7. ارائه کامل خدمات حقوقی و اداری پس از فروش.",
              ]}
            />

            <LandingCard
              direction={"row-reverse"}
              src="/images/10.png"
              title="خرید اعشاری"
              items={[
                "اندازه جیبتان سهیم باشید!",
                "تکنولوژی فروش پیشرفته و قراردادهای هوشمند ما این امکان را فراهم کرده است تا شما همراهان عزیز بتوانید با هر مبلغی که تمایل دارید سرمایه گذاری کنید. از 5 میلیون تومان تا 50 میلیارد تومان. مبلغ مورد نظر را انتخاب کنید و معادل آن، به صورت هوشمند، زمین دریافت کنید. درواقع شما محدود به خرید 1 یا چند قطعه‌ی کامل نیستید و مثلا میتوانید مالک 1.2 قطعه یا مالک 0.3 از یک قطعه باشید.",
              ]}
            />
          </Stack>
        </Container>
        <Container sx={{ my: 5 }}>
          <Counsel />
        </Container>
        <Contracts />
        <PurchaseButton stickyMode />

        <ErrorDialog
          open={openErrorPaymentDialog}
          handleClose={() => setOpenErrorPaymentDialog(false)}
        />
        <SuccessDialog
          open={openSuccessPaymentDialog}
          handleClose={() => setOpenSuccessPaymentDialog(false)}
        />
      </DashboardTemplate>
    </>
  );
};
